import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import { workflowApiRoot } from "../constants.js";
import InfoItemPlacesTwo from "../components/InfoItemPlacesTwo";
import DeleteLocalProfileButton from "../components/DeleteLocalProfileButton";
import SharedContentSelector from "../components/SharedContentSelector";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class LocalAreaContentPage extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
    // sessionStorage.setItem("routePath", "property");
  };

  checkIfChecked = (propertyId, itemId, data) => {
    var filter = data.filter((item) => {
      return (
        item._id === itemId &&
        item.LinkedProperty &&
        item.LinkedProperty.includes(propertyId)
      );
    });
    //   console.log("filter[0]", filter[0])
    if (filter[0]) return true;
    if (!filter[0]) return false;
  };

  // const sharedCustomContentFiltered = customContent
  // ? customContent.filter((item) => {
  //     return item.LinkedSharedContentProfile === ItemData._id;
  //   })
  // : null;

  //const sharedCustomContentCount = sharedCustomContentFiltered
  //// ? sharedCustomContentFiltered.length
  // : null;

  //console.log("sharedCustomContentCount", sharedCustomContentCount);

  // checkContentCount = (itemId, data) => {
  //   var filter = data.filter((item) => {
  //     return item.LinkedSharedContentProfile === itemId;
  //   });

  // console.log(" function data", data)
  //console.log("filter[0]", filter[0])
  //   if (filter[0]) return filter.length;
  //   if (!filter[0]) return 0;
  // };

  checkContentCount = (payload) => {
    fetch(`${workflowApiRoot}get-profile-content-count`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ profileId: payload }),
    })
      .then((response) => response.json())
      .then((results) => {
        if (results.status !== "success") {
          return 0;
        }
        if (results.status === "success") {
          return results.response.contentCount;
        }
        if (results.status === "success") {
          this.setState({ contentCountLoaded: true });
        }
      });
  };

  render() {
    const {
      SaveStatus,
      userData,
      propertyData,
      customContent,
      sharedContent,
      isComponentShared,
      sharedLocalAreaContentProfiles,
      SharedContentProfileId,
      sharedLocalAreaContentProfileData,
      AttachContentLoaded,
    } = this.props;

    // console.log("PropertyDetailsLocalArea SaveStatus", SaveStatus);
    // Identify page type from URL
    const pageType = window.location.pathname.split("/")[1];
    // console.log(pageType);

    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];
    // console.log(vanityPropertyId);
    // Use Detail label to lookup custom content
    const propertyDataFiltered = propertyData.filter((item) => {
      return item.PropertyID === vanityPropertyId;
    });
    const propertyDetails = propertyDataFiltered[0];
    // console.log(propertyDetails);

    // EDIT
    const propertyId = propertyDetails ? propertyDetails._id : null; //SharedContentProfileId;

    const linkedProperties = sharedLocalAreaContentProfileData
      ? sharedLocalAreaContentProfileData.LinkedProperty
      : null;

    // console.log("sharedContent", sharedContent);

    const customContentFiltered =
      customContent &&
      customContent.filter((item) => {
        return !item.LinkedSharedContentProfile;
      });

    const selectedContent = isComponentShared
      ? sharedContent
      : customContentFiltered;
    // console.log("selectedContent", selectedContent);

    const previousPagePathRoot = window.location.pathname.split("/")[1];

    const previousPagePath =
      previousPagePathRoot === "property"
        ? `/${previousPagePathRoot}/${vanityPropertyId}`
        : previousPagePathRoot === "local-area-profile"
        ? "/local-area-profiles"
        : "/";

    const previousPageTitle =
      previousPagePathRoot === "property" &&
      propertyDetails &&
      propertyDetails.PropertyTitle
        ? propertyDetails.PropertyTitle
        : previousPagePathRoot === "local-area-profile"
        ? "Local Area Profiles"
        : "/";

    const currentPageTitle = isComponentShared
      ? `${sharedLocalAreaContentProfileData.Title} Local Area Recommendations`
      : "Local Area Recommendations";

    return (
      <>
        <NavigationHeader
          {...this.props}
          propertyDetails={propertyDetails}
          pathRoot={previousPagePath}
          previousPageTitle={previousPageTitle}
          previousPagePath={previousPagePath}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>

            {!isComponentShared && sharedLocalAreaContentProfiles[0] && (
              <>
                <div className="infoitem-container-box">
                  <div className="infoitem-container-box-heading">
                    Local Area Profiles
                  </div>

                  {sharedLocalAreaContentProfiles.map((item) => (
                    <SharedContentSelector
                      key={item._id}
                      ItemUniqueId={`local-profile-${item._id}`}
                      UserId={userData._id}
                      ContentId={item._id}
                      PropertyId={propertyDetails._id}
                      SharedContentProfileId={item._id}
                      SaveStatus={SaveStatus}
                      IsChecked={
                        item.LinkedProperty && item.LinkedProperty[0] //&& propertyDetails && propertyDetails[0]
                          ? this.checkIfChecked(
                              propertyDetails._id,
                              item._id,
                              sharedLocalAreaContentProfiles
                            )
                          : null
                      }
                      ItemHeading={item.Title}
                      AttachContentLoaded={AttachContentLoaded}
                      ItemData={item}
                      customContent={customContent}
                      sharedLocalAreaContentProfileData={
                        sharedLocalAreaContentProfileData
                      }
                      ItemLabel={`${item.Label}-local-profile`}
                      ContentType={"local-profile"}
                      //     SharedCustomContentCount={this.checkContentCount(item._id)
                      //    }
                    />
                  ))}
                </div>
              </>
            )}

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              SharedContentProfileId={SharedContentProfileId}
              //  vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"special-offers"}
              ItemHeading={"Special Offers"}
              ItemDescription={
                "Provide details of special offers for local places. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"special-offers"}
              // PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              // sharedContent={sharedContent}
              //     SharedContentLoaded={SharedContentLoaded}
              //       SharedContentLoadedId={SharedContentLoadedId}
              //   isComponentShared={true}
              //       sharedContentProfiles={sharedContentProfiles}

              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              SharedContentProfileId={SharedContentProfileId}
              //  vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"events"}
              ItemHeading={"Events"}
              ItemDescription={
                "Provide recommendations for local events. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"events"}
              // PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              // sharedContent={sharedContent}
              //     SharedContentLoaded={SharedContentLoaded}
              //       SharedContentLoadedId={SharedContentLoadedId}
              //   isComponentShared={true}
              //       sharedContentProfiles={sharedContentProfiles}

              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              SharedContentProfileId={SharedContentProfileId}
              //  vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"experiences"}
              ItemHeading={"Experiences"}
              ItemDescription={
                "Provide recommendations for local experiences. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"experiences"}
              // PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              // sharedContent={sharedContent}
              //     SharedContentLoaded={SharedContentLoaded}
              //       SharedContentLoadedId={SharedContentLoadedId}
              //   isComponentShared={true}
              //       sharedContentProfiles={sharedContentProfiles}

              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              SharedContentProfileId={SharedContentProfileId}
              //  vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"attraction"}
              ItemHeading={"Attractions"}
              ItemDescription={
                "Provide recommendations for local attractions. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"attraction"}
              // PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              // sharedContent={sharedContent}
              //     SharedContentLoaded={SharedContentLoaded}
              //       SharedContentLoadedId={SharedContentLoadedId}
              //   isComponentShared={true}
              //       sharedContentProfiles={sharedContentProfiles}

              linkedProperties={linkedProperties}
            />

            {
              //       <InfoItemPlaces
              //      {...this.props}
              //       SaveStatus={SaveStatus}
              //      propertyId={propertyId}
              //      SharedContentProfileId={SharedContentProfileId}
              //       vanityPropertyId={vanityPropertyId}
              //       userId={userData._id}
              //       ItemLabel={"Attractions"}
              //       ItemHeading={"Attractions"}
              //       ItemDescription={
              //        "Provide recommendations for local attractions. These will appear on the map and information sections in your app."
              //      }
              //      IsRequired={false}
              //       FalseText={"No places added yet"}
              //       propertyDetails={propertyDetails}
              //       customContent={selectedContent}
              //       CategoryLabel={"attraction"}
              //       PageType={pageType}
              //        isComponentShared={isComponentShared}
              //        sharedContent={sharedContent}
              //        sharedLocalAreaContentProfileData={
              //          sharedLocalAreaContentProfileData
              //        }
              //        linkedProperties={linkedProperties}
              //      />
            }

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"Dining and Nightlife"}
              ItemHeading={"Dining and Nightlife"}
              ItemDescription={
                "Provide recommendations for local restaurants and bars. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"food-and-drink"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"Cafes"}
              ItemHeading={"Cafes"}
              ItemDescription={
                "Provide recommendations for local cafes. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"cafes"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"Leisure activities"}
              ItemHeading={"Leisure activities"}
              ItemDescription={
                "Provide recommendations for local leisure activities. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"leisure-activity"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

    <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"casino"}
              ItemHeading={"Casinos"}
              ItemDescription={
                "Provide recommendations for local casinos. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"casino"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />
                
            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"health-services"}
              ItemHeading={"Health Services"}
              ItemDescription={
                "Provide recommendations for local health services. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"health-services"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"health-and-beauty"}
              ItemHeading={"Fitness and Beauty"}
              ItemDescription={
                "Provide recommendations for local fitness and beauty establishments. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"health-and-fitness"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"Shopping"}
              ItemHeading={"Shopping"}
              ItemDescription={
                "Provide recommendations for local shopping. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"shopping"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"Amenities and Local Businesses"}
              ItemHeading={"Amenities and Local Businesses"}
              ItemDescription={
                "Provide recommendations for local amenities and businesses. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"amenity"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"Transport"}
              ItemHeading={"Transport"}
              ItemDescription={
                "Provide recommendations for local transport services. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"transport"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"parks"}
              ItemHeading={"Parks and Outdoor Space"}
              ItemDescription={
                "Provide recommendations for local parks and recreational spaces. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"parks"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"walking-hiking-and-running"}
              ItemHeading={"Walking, Hiking and Running"}
              ItemDescription={
                "Provide recommendations for local walking, hiking, and running routes. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"routes"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"cycling-routes"}
              ItemHeading={"Cycling"}
              ItemDescription={
                "Provide recommendations for local cycling routes and facilities. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"cycling"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"beaches"}
              ItemHeading={"Beaches"}
              ItemDescription={
                "Provide recommendations for local beaches. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"beaches"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"watersports"}
              ItemHeading={"Watersports"}
              ItemDescription={
                "Provide recommendations for local watersports such as surfing, sailing, kayaking, scuba diving and yachting. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"watersports"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"golf"}
              ItemHeading={"Golf"}
              ItemDescription={
                "Provide recommendations for local golf courses and facilities. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"golf"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"Skiing and Snowboarding"}
              ItemHeading={"Skiing and Snowboarding"}
              ItemDescription={
                "Provide recommendations for local skiing and snowboarding areas. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"skiing"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"Wineries and Vineyards"}
              ItemHeading={"Wineries and Vineyards"}
              ItemDescription={
                "Provide recommendations for local wineries and vineyards. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"vineyard"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"fishing"}
              ItemHeading={"Fishing"}
              ItemDescription={
                "Provide recommendations for local fishing. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"fishing"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"wildlife"}
              ItemHeading={"Local Wildlife"}
              ItemDescription={
                "Provide recommendations for places to see local wildlife. These will appear on the map and information sections in your app."
              }
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"wildlife"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            <InfoItemPlacesTwo
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyId}
              vanityPropertyId={vanityPropertyId}
              userId={userData._id}
              ItemLabel={"accommodation-locations"}
              ItemHeading={"Accommodation Locations"}
              ItemDescription={"Provide details of your accomodation options."}
              IsRequired={false}
              FalseText={"No places added yet"}
              propertyDetails={propertyDetails}
              customContent={selectedContent}
              CategoryLabel={"accommodation-locations"}
              PageType={pageType}
              isComponentShared={isComponentShared}
              sharedContent={sharedContent}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
              linkedProperties={linkedProperties}
            />

            {isComponentShared && (
              <DeleteLocalProfileButton
                {...this.props}
                userId={userData._id}
                SharedContentProfileId={SharedContentProfileId}
                ItemLabel={"delete-local-profile"}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
