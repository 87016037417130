import {
  liveStatus,
  dataApiRoot,
  workflowApiRoot,
  storageRootUrl,
  systemId,
  apiSecretToken,
  storageApiKey,
  appVersion,
  cdnRootUrl,
} from "../../constants.js";

import { pushConversationMessage } from "../../data/pushConversationMessage";
import { fetchSystemContentCategories } from "../../data/fetchSystemContentCategories";
import { fetchCustomContentCategories } from "../../data/fetchCustomContentCategories";

// Action Creators

const setFetchStatus = (payload) => ({
  type: "FETCH_STATUS",
  payload,
});

export const setSaveStatus = (payload) => ({
  type: "SAVE_STATUS",
  payload,
});

const setResponseData = (payload) => ({
  type: "SET_RESPONSE_DATA",
  payload,
});

export const setSubmittingStatus = (payload) => ({
  type: "SET_SUBMITTING_STATUS",
  payload,
});

export const logUserOut = () => ({ type: "LOG_OUT" });

const setUser = (payload) => ({ type: "SET_USER", payload });

const setUserData = (payload) => ({ type: "SET_USER_DATA", payload });

export const setPropertyId = (payload, propertyId) => ({
  type: "SET_PROPERTY_ID",
  payload,
  propertyId,
});

const setLinkedProperties = (payload) => ({
  type: "SET_LINKED_PROPERTIES_DATA",
  payload,
});

export const setLinkedPropertiesLoaded = (payload) => ({
  type: "SET_LINKED_PROPERTIES_LOADED",
  payload,
});

const setLinkedPropertyManagers = (payload) => ({
  type: "SET_LINKED_MANAGERS_DATA",
  payload,
});

export const setLinkedPropertyManagersLoaded = (payload) => ({
  type: "SET_LINKED_MANAGERS_LOADED",
  payload,
});

const setLinkedManagementCompany = (payload) => ({
  type: "SET_LINKED_MANAGEMENTCO_DATA",
  payload,
});

export const setLinkedManagementCompanyLoaded = (payload) => ({
  type: "SET_LINKED_MANAGEMENTCO_LOADED",
  payload,
});

export const setSystemCategories = (payload) => ({
  type: "SET_SYSTEM_CATEGORIES",
  payload,
});

export const setCustomCategories = (payload) => ({
  type: "SET_CUSTOM_CATEGORIES",
  payload,
});

export const setCustomContent = (payload, propertyId) => ({
  type: "SET_CUSTOM_CONTENT",
  payload,
  propertyId,
});

export const setCustomContentLoaded = (payload, propertyId) => ({
  type: "SET_CUSTOM_CONTENT_LOADED",
  payload,
  propertyId,
});

const setSharedProfiles = (payload, itemUniqueId) => ({
  type: "SET_SHARED_PROFILES",
  payload,
  itemUniqueId,
});

export const setSharedProfilesLoaded = (payload) => ({
  type: "SET_SHARED_PROFILES_LOADED",
  payload,
});

const setSharedContent = (payload, profileId) => ({
  type: "SET_SHARED_CONTENT",
  payload,
  profileId,
});

export const setSharedContentLoaded = (payload, profileId) => ({
  type: "SET_SHARED_CONTENT_LOADED",
  payload,
  profileId,
});

export const setAttachContentLoaded = (payload) => ({
  type: "SET_ATTACH_CONTENT_LOADED",
  payload,
});

const setSinglePropertyData = (propertyId, payload) => ({
  type: "SET_SINGLE_PROPERTY_DATA",
  propertyId,
  payload,
});

export const setPropertyContentLoaded = (payload) => ({
  type: "SINGLE_PROPERTY_DATA_LOADED",
  payload,
});

export const setFileLoadStatus = (payload) => ({
  type: "SET_FILE_LOAD_STATUS",
  payload,
});

const setSubscriptionData = (payload) => ({
  type: "SET_SUBSCRIPTION_DATA",
  payload,
});

export const setSubscriptionDataLoaded = (payload) => ({
  type: "SUBSCRIPTION_DATA_LOADED",
  payload,
});

const setReferralData = (payload) => ({
  type: "SET_REFERRAL_DATA",
  payload,
});

const setReferralDataLoaded = (payload) => ({
  type: "SET_REFERRAL_DATA_LOADED",
  payload,
});

const setAnalyticsData = (payload) => ({
  type: "SET_ANALYTICS_DATA",
  payload,
});

const setAnalyticsDataLoaded = (payload) => ({
  type: "SET_ANALYTICS_DATA_LOADED",
  payload,
});

export const setConversationData = (payload) => ({
  type: "SET_CONVERSATION_DATA",
  payload,
});

const setConversationDataLoaded = (payload) => ({
  type: "SET_CONVERSATION_DATA_LOADED",
  payload,
});

export const setMessageData = (payload) => ({
  type: "SET_MESSAGE_DATA",
  payload,
});

const setMessageDataLoaded = (payload) => ({
  type: "SET_MESSAGE_DATA_LOADED",
  payload,
});

const setDataLoadPercent = (payload) => ({
  type: "SET_DATA_LOAD_PERCENT",
  payload,
});

export const conversationFileUploads = (payload) => ({
  type: "SET_CONVERSATION_FILE_UPLOADS",
  payload,
});
export const clearConversationFileUploads = (payload) => ({
  type: "CLEAR_CONVERSATION_FILE_UPLOADS",
  payload,
});
export const conversationFileUploadsLoading = (payload) => ({
  type: "SET_CONVERSATION_FILE_UPLOADS_LOADING",
  payload,
});

export const setLoadingStatus = (payload) => ({
  type: "SET_LOADING_STATUS",
  payload,
});

// Methods

export const clickLogout = (history) => (dispatch) => {
  fetch(`${workflowApiRoot}logout/initializeuser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      //     console.log(data);
      if (data.status === "success") {
        sessionStorage.clear();
        dispatch(logUserOut());
        history.push("/");
        //  dispatch(autoLogin());
      } else {
        dispatch(setResponseData(data));
      }
    });
};

// Retrieves user data when login token is set

export const autoLogin = (ItemLabel) => (dispatch) => {
  var payLoad = {
    userData: null,
    systemContent: null,
    // systemCategories: null,
    linkedProperties: null,
    linkedPropertyManagers: null,
    linkedManagementCo: null,
    sharedContentProfiles: null,
  };

  if (sessionStorage.getItem("token")) {
    // Get params to identify if user current or lapsed subscriber
    let params = new URL(window.location.toString()).searchParams;
    let s = params.get("s");
    // console.log("s", s);

    fetch(`${workflowApiRoot}get-user-data/initializeuser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ liveStatus: liveStatus }),
    })
      .then((res) => res.json())
      .then((data) => {
        //  console.log("get-user-data", data);
        if (data.status === "success") {
          let dataEdit = data.response.user;
          delete dataEdit["Admin"];
          dataEdit.unreadMessageCount = data.response.unreadMessageCount;
          payLoad.userData = dataEdit;
          const userId = dataEdit._id;
          // console.log("userId", userId);

          // Reload page to add params to identify if user current or lapsed subscriber
          if (!s || s !== "1") {
            if (dataEdit.DefaultCurrency) {
              // window.location.href = window.location.toString() + "?s=1";
              if (window.location.toString().includes("?")) {
                window.location.href = window.location.toString() + "&s=1";
              } else {
                window.location.href = window.location.toString() + "?s=1";
              }
            }
          }

          // Get geo location data
          fetch("https://ipapi.co/json/")
            .then((res) => res.json())
            .then((data) => {
              payLoad.geoLocationData = data;
            });

          fetchSystemContentCategories(dispatch, systemId);
          fetchCustomContentCategories(dispatch, userId);

          // Paginated API Request
          const getSystemContentData = async function (cursor) {
            let apiUrl = `${dataApiRoot}systemcontent?constraints=[{%22key%22:%22systemid%22,%22constraint_type%22:%22equals%22,%22value%22:%22${systemId}%22}]&cursor=${cursor}`;
            var apiResults = await fetch(apiUrl, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${apiSecretToken}`,
              },
            }).then((resp) => {
              return resp.json();
            });
            return apiResults;
          };

          const getAllSystemContentData = async function (cursor = 0) {
            const data = await getSystemContentData(cursor);
            const { remaining, results } = data.response;

            if (remaining > 0) {
              cursor += results.length;
              return results.concat(await getAllSystemContentData(cursor));
            } else {
              return results;
            }
          };

          (async () => {
            const entireSystemContentList = await getAllSystemContentData();
            //  console.log(entireSystemContentList);
            payLoad.systemContent = entireSystemContentList;

            // Non-paginated API Requests
            // return fetch(
            //   `${dataApiRoot}contentcategory?constraints=[{%22key%22:%22systemid%22,%22constraint_type%22:%22equals%22,%22value%22:%22${systemId}%22}]&sort_field=listrank`,
            //   {
            //    method: "GET",
            //   headers: {
            //      "Content-Type": "application/json",
            //     Accept: "application/json",
            //     Authorization: `Bearer ${apiSecretToken}`,
            //   },
            // }
            // )
            //   .then((res) => res.json())
            //   .then((data) => {
            //    payLoad.systemCategories = data.response.results;

            // Paginated API Request for Properties
            const getPropertyData = async function (cursor) {
              let apiUrl = `${dataApiRoot}property?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${payLoad.userData._id}%22},{%22key%22:%22isarchived%22,%22constraint_type%22:%22equals%22,%22value%22:%22false%22}]&sort_field=Modified Date&descending=true&cursor=${cursor}`;
              var apiResults = await fetch(apiUrl, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
              }).then((resp) => {
                return resp.json();
              });
              return apiResults;
            };

            const getAllPropertyData = async function (cursor = 0) {
              const data = await getPropertyData(cursor);
              const { remaining, results } = data.response;

              if (remaining > 0) {
                cursor += results.length;
                return results.concat(await getAllPropertyData(cursor));
              } else {
                return results;
              }
            };

            (async () => {
              const entirePropertyList = await getAllPropertyData();
              //   console.log(entirePropertyList);
              payLoad.linkedProperties = entirePropertyList;
            })();

            return fetch(
              `${dataApiRoot}propertymanager?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${payLoad.userData._id}%22}]`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                payLoad.linkedPropertyManagers = data.response.results;

                return fetch(
                  `${dataApiRoot}managementcompany?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${payLoad.userData._id}%22}]`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization: `Bearer ${sessionStorage.getItem(
                        "token"
                      )}`,
                    },
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    payLoad.linkedManagementCo = data.response.results;

                    // Get Shared Content Profiles

                    return fetch(
                      `${dataApiRoot}sharedcontentprofile?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${payLoad.userData._id}%22}]`,
                      {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          Accept: "application/json",
                          Authorization: `Bearer ${sessionStorage.getItem(
                            "token"
                          )}`,
                        },
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        payLoad.sharedContentProfiles = data.response.results;

                        if (data.response) {
                          dispatch(setUser(payLoad));
                          dispatch(setSaveStatus(`${ItemLabel}-success`));
                        }
                        if (!data.response) {
                          dispatch(setResponseData(data));
                        }
                      });
                  });

                // End Get Shared Content Profiles
              });
            //  });
            // above curley & purple remove?
          })();
        } else {
          dispatch(setResponseData(null));
          sessionStorage.clear();
        }
      });
  } else {
    dispatch(setResponseData(null));
    dispatch(setUserData({}));
  }
};

// Retrieves Custom Content

export const getCustomContent = (propertyId, ItemLabel) => (dispatch) => {
  // dispatch(setLoading());
  var payLoad = {
    linkedCustomContent: null,
  };

  //  console.log("getCustomContent triggered success")
  if (sessionStorage.getItem("token")) {
    const getCustomContentData = async function (cursor) {
      let apiUrl = `${dataApiRoot}customcontent?constraints=[{%22key%22:%22linkedproperty%22,%22constraint_type%22:%22contains%22,%22value%22:%22${propertyId}%22}]&sort_field=listrank&cursor=${cursor}`;
      var apiResults = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }).then((resp) => {
        return resp.json();
      });
      return apiResults;
    };

    const getAllCustomContentData = async function (cursor = 0) {
      const data = await getCustomContentData(cursor);
      const { remaining, results } = data.response;
      //   console.log(data);
      //  console.log("Retreiving data from API for cursor position: " + cursor);
      if (remaining > 0) {
        cursor += results.length;
        return results.concat(await getAllCustomContentData(cursor));
      } else {
        return results;
      }
    };

    (async () => {
      const entireList = await getAllCustomContentData();
      payLoad.linkedCustomContent = entireList;
      // console.log("payLoad", payLoad.linkedCustomContent);
      if (payLoad.linkedCustomContent) {
        //  console.log("payLoad.linkedCustomContent", payLoad.linkedCustomContent)
        //  console.log("propertyId", propertyId)
        dispatch(setPropertyId(true, propertyId));
        dispatch(setCustomContent(payLoad.linkedCustomContent, propertyId));
        dispatch(setSaveStatus(`${ItemLabel}-success`));
      }
      if (!payLoad.linkedCustomContent) {
        dispatch(setResponseData("data"));
      }
    })();
  } else {
    dispatch(setResponseData(null));
  }
};

// Get shared content profiles

export const getSharedContentProfiles = (userId, ItemLabel) => (dispatch) => {
  fetch(
    `${dataApiRoot}sharedcontentprofile?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22}]`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      //  console.log("getSharedContentProfiles response", data.response);
      if (data) {
        dispatch(
          setSharedProfiles(data.response.results, `${ItemLabel}-success`)
        );
        dispatch(setSaveStatus(`${ItemLabel}-success`));
        //  dispatch(setAttachContentLoaded(`${ItemLabel}-success`));
      } else {
        dispatch(setResponseData(data));
      }
    });
};

// Retrieves Shared Content

export const getSharedContent =
  (sharedContentProfileId, ItemLabel) => (dispatch) => {
    var payLoad = {
      sharedCustomContent: null,
    };

    if (sessionStorage.getItem("token")) {
      const getSharedContentData = async function (cursor) {
        let apiUrl = `${dataApiRoot}customcontent?constraints=[{%22key%22:%22linkedsharedcontentprofile%22,%22constraint_type%22:%22equals%22,%22value%22:%22${sharedContentProfileId}%22}]&sort_field=listrank&cursor=${cursor}`;
        var apiResults = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }).then((resp) => {
          return resp.json();
        });
        return apiResults;
      };

      const getAllSharedContentData = async function (cursor = 0) {
        const data = await getSharedContentData(cursor);
        const { remaining, results } = data.response;
        //   console.log(data);
        //   console.log("Retreiving data from API for cursor position: " + cursor);
        if (remaining > 0) {
          cursor += results.length;
          return results.concat(await getAllSharedContentData(cursor));
        } else {
          return results;
        }
      };

      (async () => {
        const entireList = await getAllSharedContentData();
        payLoad.sharedCustomContent = entireList;
        //    console.log("payLoad", payLoad);
        if (payLoad.sharedCustomContent) {
          //  dispatch(setPropertyId(true, propertyId));
          dispatch(
            setSharedContent(
              payLoad.sharedCustomContent,
              sharedContentProfileId
            )
          );
          dispatch(setAttachContentLoaded(`${ItemLabel}-success`));
          dispatch(setSaveStatus(`${ItemLabel}-success`));
        }
        if (!payLoad.sharedCustomContent) {
          dispatch(setResponseData("data"));
        }
      })();
    } else {
      dispatch(setResponseData(null));
    }
  };

// Retrieves Property Data

export const getSinglePropertyData = (propertyId, ItemLabel) => (dispatch) => {
  dispatch(setFetchStatus("fetching"));
  fetch(`${dataApiRoot}property/${propertyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      //    console.log(data.response);
      if (data) {
        dispatch(setSinglePropertyData(propertyId, data.response));
        dispatch(setSaveStatus(`${ItemLabel}-success`));
      } else {
        dispatch(setResponseData(data));
      }
    });
};

// Retrieves Referral Data

export const getReferralData = () => (dispatch) => {
  dispatch(setReferralDataLoaded(false));
  fetch(`${workflowApiRoot}get-referral-data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === "success") {
        //  console.log(data.response);
        dispatch(setReferralData(data.response));
      } else {
        dispatch(setResponseData(data));
        dispatch(setFetchStatus("error"));
      }
    });
};

export const getAnalyticsData =
  (userId, propertyId, startDate, endDate) => (dispatch) => {
    dispatch(setAnalyticsDataLoaded(false));
    dispatch(setDataLoadPercent(""));
    var payLoad = {
      analyticsData: null,
    };

    const startDateFormatted = new Date(startDate);
    const endDateFormatted = new Date(endDate);

    if (sessionStorage.getItem("token")) {
      const fetchAnalyticsData = async function (cursor) {
        let apiUrl = `${dataApiRoot}appanalytics?constraints=[{%22key%22:%22linkeduserid%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22},{%22key%22:%22propertyid%22,%22constraint_type%22:%22equals%22,%22value%22:%22${propertyId}%22},{%22key%22:%22createddate%22,%22constraint_type%22:%22greater%20than%22,%22value%22:%22${startDateFormatted}%22},{%22key%22:%22createddate%22,%22constraint_type%22:%22less%20than%22,%22value%22:%22${endDateFormatted}%22}]&sort_field=createddate&descending=true&cursor=${cursor}`;
        var apiResults = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }).then((resp) => {
          return resp.json();
        });
        return apiResults;
      };

      const getAllAnalyticsData = async function (cursor = 0) {
        const data = await fetchAnalyticsData(cursor);
        const { remaining, results } = data.response;
        const count = data.response.cursor;
        const percentage = (count / (count + remaining)) * 100;
        const percentageFormatted = percentage.toFixed(0) + "%";
        // console.log(percentageFormatted);

        //   console.log("Retreiving data from API for cursor position: " + cursor);
        if (remaining > 0) {
          dispatch(setDataLoadPercent(percentageFormatted));
          cursor += results.length;
          return results.concat(await getAllAnalyticsData(cursor));
        } else {
          dispatch(setDataLoadPercent("100%"));
          return results;
        }
      };

      (async () => {
        const entireList = await getAllAnalyticsData();
        payLoad.analyticsData = entireList;
        //    console.log("payLoad", payLoad);
        if (payLoad.analyticsData) {
          dispatch(setAnalyticsData(payLoad.analyticsData));
        }
        if (!payLoad.analyticsData) {
          dispatch(setResponseData("data"));
        }
      })();
    } else {
      dispatch(setResponseData(null));
    }
  };

// Get all conversations for the user
export const getConversationData =
  (userId, propertyId, startDate, endDate) => (dispatch) => {
    dispatch(setConversationDataLoaded(false));
    dispatch(setDataLoadPercent(""));
    var payLoad = {
      conversationData: null,
    };
    // console.log("data passed", userId, propertyId, startDate, endDate);
    const startDateFormatted = new Date(startDate);
    const endDateFormatted = new Date(endDate);

    if (sessionStorage.getItem("token")) {
      const fetchConversationData = async function (cursor) {
        let apiUrl = `${dataApiRoot}conversations?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22},{%22key%22:%22linkedproperty%22,%22constraint_type%22:%22equals%22,%22value%22:%22${propertyId}%22},{%22key%22:%22createddate%22,%22constraint_type%22:%22greater%20than%22,%22value%22:%22${startDateFormatted}%22},{%22key%22:%22createddate%22,%22constraint_type%22:%22less%20than%22,%22value%22:%22${endDateFormatted}%22}]&sort_field=createddate&descending=true&cursor=${cursor}`;
        var apiResults = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }).then((resp) => {
          return resp.json();
        });
        return apiResults;
      };

      const getAllConversationData = async function (cursor = 0) {
        const data = await fetchConversationData(cursor);
        // console.log("data", data)
        const { remaining, results } = data.response;
        const count = data.response.cursor;
        const percentage = (count / (count + remaining)) * 100;
        const percentageFormatted = percentage.toFixed(0) + "%";
        // console.log(percentageFormatted);

        //   console.log("Retreiving data from API for cursor position: " + cursor);
        if (remaining > 0) {
          dispatch(setDataLoadPercent(percentageFormatted));
          cursor += results.length;
          return results.concat(await getAllConversationData(cursor));
        } else {
          dispatch(setDataLoadPercent("100%"));
          return results;
        }
      };

      (async () => {
        const entireList = await getAllConversationData();
        payLoad.conversationData = entireList;
        //    console.log("payLoad", payLoad);
        if (payLoad.conversationData) {
          dispatch(setConversationData(payLoad.conversationData));
        }
        if (!payLoad.conversationData) {
          dispatch(setResponseData("data"));
        }
      })();
    } else {
      dispatch(setResponseData(null));
    }
  };

// Get conversation data for a single conversation
export const getSingleConversationData = (conversationId) => (dispatch) => {
  dispatch(setConversationDataLoaded(false));
  dispatch(setDataLoadPercent(""));
  var payLoad = {
    conversationData: null,
  };

  if (sessionStorage.getItem("token")) {
    const fetchSingleConversationData = async function (cursor) {
      let apiUrl = `${dataApiRoot}conversations?constraints=[{%22key%22:%22_id%22,%22constraint_type%22:%22equals%22,%22value%22:%22${conversationId}%22}]&sort_field=createddate&descending=true&cursor=${cursor}`;
      var apiResults = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }).then((resp) => {
        return resp.json();
      });
      return apiResults;
    };

    const getAllSingleConversationData = async function (cursor = 0) {
      const data = await fetchSingleConversationData(cursor);
      // console.log("data", data)
      const { remaining, results } = data.response;
      const count = data.response.cursor;
      const percentage = (count / (count + remaining)) * 100;
      const percentageFormatted = percentage.toFixed(0) + "%";
      // console.log(percentageFormatted);

      //   console.log("Retreiving data from API for cursor position: " + cursor);
      if (remaining > 0) {
        dispatch(setDataLoadPercent(percentageFormatted));
        cursor += results.length;
        return results.concat(await getAllSingleConversationData(cursor));
      } else {
        dispatch(setDataLoadPercent("100%"));
        return results;
      }
    };

    (async () => {
      const entireList = await getAllSingleConversationData();
      payLoad.conversationData = entireList;
      //    console.log("payLoad", payLoad);
      if (payLoad.conversationData) {
        dispatch(setConversationData(payLoad.conversationData));
        dispatch(getMessageData(conversationId));
      }
      if (!payLoad.conversationData) {
        dispatch(setResponseData("data"));
      }
    })();
  } else {
    dispatch(setResponseData(null));
  }
};

export const getMessageData = (conversationId) => (dispatch) => {
  dispatch(setMessageDataLoaded(false));
  dispatch(setDataLoadPercent(""));
  var payLoad = {
    messageData: null,
  };

  if (sessionStorage.getItem("token")) {
    const fetchMessageData = async function (cursor) {
      let apiUrl = `${dataApiRoot}conversationmessages?constraints=[{%22key%22:%22linkedconversation%22,%22constraint_type%22:%22equals%22,%22value%22:%22${conversationId}%22}]&sort_field=createddate&descending=false&cursor=${cursor}`;
      var apiResults = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }).then((resp) => {
        return resp.json();
      });
      return apiResults;
    };

    const getAllMessageData = async function (cursor = 0) {
      const data = await fetchMessageData(cursor);
      // console.log("data", data)
      const { remaining, results } = data.response;
      const count = data.response.cursor;
      const percentage = (count / (count + remaining)) * 100;
      const percentageFormatted = percentage.toFixed(0) + "%";
      // console.log(percentageFormatted);

      //   console.log("Retreiving data from API for cursor position: " + cursor);
      if (remaining > 0) {
        dispatch(setDataLoadPercent(percentageFormatted));
        cursor += results.length;
        return results.concat(await getAllMessageData(cursor));
      } else {
        dispatch(setDataLoadPercent("100%"));
        return results;
      }
    };

    (async () => {
      const entireList = await getAllMessageData();
      payLoad.messageData = entireList;
      //  console.log("payLoad.messageData", payLoad.messageData);
      if (payLoad.messageData) {
        dispatch(setMessageData(payLoad.messageData));
      }
      if (!payLoad.messageData) {
        dispatch(setResponseData("data"));
      }
    })();
  } else {
    dispatch(setResponseData(null));
  }
};

// Send outbound message
export const sendOutboundMessage =
  (propertyId, conversationId, detailText) => (dispatch) => {
    // console.log("runs", propertyId, conversationId, detailText);
    fetch(`${workflowApiRoot}send-outbound-message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        conversationId: conversationId,
        DetailText: detailText,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log("data", data);
        if (data.status === "success") {
          dispatch(getMessageData(conversationId));
          pushConversationMessage({
            propertyId: propertyId,
            conversationId: conversationId,
          });
          // dispatch(setSubscriptionData(data.response));
        } else {
          dispatch(setResponseData(data));
          //  dispatch(setFetchStatus("error"));
        }
      });
  };

// Start getSubscriptionData
export const getSubscriptionData = () => (dispatch) => {
  dispatch(setSubscriptionDataLoaded(false));
  fetch(`${workflowApiRoot}get-subscription-data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify({ liveStatus: liveStatus }),
  })
    .then((res) => res.json())
    .then((data) => {
      // console.log("getSubscriptionData", data);
      if (data.status === "success") {
        dispatch(setSubscriptionData(data.response));
      } else {
        dispatch(setResponseData(data));
        dispatch(setFetchStatus("error"));
      }
    });
};

// End getSubscriptionData

// Logs in user

export const fetchUser = (userInfo) => (dispatch) => {
  dispatch(setFetchStatus("fetching"));
  fetch(`${workflowApiRoot}hagiy-jdhbs-es/initializeuser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${apiSecretToken}`,
    },
    body: JSON.stringify(userInfo),
  })
    .then((res) => res.json())
    .then((data) => {
      //   console.log("Login data", data.response);
      if (data.status === "success") {
        sessionStorage.setItem("token", data.response.token);
        dispatch(setFetchStatus("loading-data"));
        //  console.log("Client appVersion", appVersion);
        //   console.log("Server appVersion", data.response.appVersion);

        if (data.response.appVersion !== appVersion) {
          fetch("https://ipapi.co/json/")
            .then((res) => res.json())
            .then((data) => {
              //   console.log(data);

              const loginData = {
                email: `${userInfo.email}`,
                password: `${userInfo.password}`,
                ipAddress: data.ip,
                city: data.city,
                country: data.country_name,
                attemptedAppVersion: appVersion,
                currentAppVersion: "n/a",
                successStatus: true,
                appReloaded: true,
              };

              fetch(`${workflowApiRoot}login-check`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${apiSecretToken}`,
                },
                body: JSON.stringify(loginData),
              });
            });

          window.location.reload();
        }
        if (data.response.appVersion === appVersion) {
          fetch("https://ipapi.co/json/")
            .then((res) => res.json())
            .then((data) => {
              // console.log(data);

              const loginData = {
                email: `${userInfo.email}`,
                password: `${userInfo.password}`,
                ipAddress: data.ip,
                city: data.city,
                country: data.country_name,
                attemptedAppVersion: appVersion,
                currentAppVersion: "n/a",
                successStatus: true,
                appReloaded: false,
              };

              fetch(`${workflowApiRoot}login-check`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${apiSecretToken}`,
                },
                body: JSON.stringify(loginData),
              });
            });

          dispatch(autoLogin());
        }
      } else {
        // dispatch(setResponseData(data));

        dispatch(setFetchStatus("error"));

        //console.log("this runs")
        fetch("https://ipapi.co/json/")
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);

            const loginData = {
              email: `${userInfo.email}`,
              password: `${userInfo.password}`,
              ipAddress: data.ip,
              city: data.city,
              country: data.country_name,
              attemptedAppVersion: appVersion,
              currentAppVersion: "n/a",
              successStatus: false,
              appReloaded: false,
            };

            fetch(`${workflowApiRoot}login-check`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${apiSecretToken}`,
              },
              body: JSON.stringify(loginData),
            })
              .then((res) => res.json())
              .then((data) => {
                dispatch(setResponseData(data.response));
              });
          });
      }
    });
};

// This function updates user information in the database
// and sets response state for InfoItem components, controlled by the ItemLabel

export const updateUser = (userInfo, userId, ItemLabel) => (dispatch) => {
  dispatch(setSaveStatus(`${ItemLabel}-fetching`));
  fetch(`${dataApiRoot}user/${userId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(userInfo),
  }).then(function (response) {
    if (response.status !== 204) {
      dispatch(setSaveStatus(`${ItemLabel}-error`));
    }
    if (response.status === 204) {
      fetch(`${dataApiRoot}user/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            dispatch(setUserData(data.response));
            dispatch(setSaveStatus(`${ItemLabel}-success`));
          } else {
            dispatch(setResponseData(data));
          }
        });
    }
  });
};

// Create a new property manager

export const createCompany = (companyInfo, ItemLabel, userId) => (dispatch) => {
  dispatch(setSaveStatus(`${ItemLabel}-fetching`));
  dispatch(setLinkedManagementCompanyLoaded(false));
  fetch(`${workflowApiRoot}create-company`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(companyInfo),
  })
    .then((response) => response.json())
    .then((results) => {
      //  console.log("Create Manager Response", results);
      if (results.status !== "success") {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (results.status === "success") {
        dispatch(getLinkedManagementCompany(ItemLabel, userId));
        dispatch(autoLogin(ItemLabel));
      }
    });
};

// This function updates company information in the database
// and sets response state for InfoItem components, controlled by the ItemLabel

export const updateCompany =
  (companyInfo, companyId, ItemLabel, userId) => (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));
    fetch(`${dataApiRoot}managementcompany/${companyId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(companyInfo),
    }).then(function (response) {
      // console.log(response.status);
      if (response.status !== 204) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 204) {
        dispatch(getLinkedManagementCompany(ItemLabel, userId));
      }
    });
  };

// Get Linked Property Managers

export const getLinkedPropertyManagers = (ItemLabel, userId) => (dispatch) => {
  dispatch(setSaveStatus(`${ItemLabel}-fetching`));
  // dispatch(setLinkedPropertyManagersLoaded(false));
  fetch(
    `${dataApiRoot}propertymanager?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22}]`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      //   console.log("Get Property Managers Response", data);
      if (data) {
        dispatch(setLinkedPropertyManagers(data.response.results));
        dispatch(setSaveStatus(`${ItemLabel}-success`));
      } else {
        dispatch(setResponseData(data));
      }
    });
};

// Create a new property manager

export const createManager = (managerInfo, ItemLabel, userId) => (dispatch) => {
  dispatch(setSaveStatus(`${ItemLabel}-fetching`));
  dispatch(setLinkedPropertyManagersLoaded(false));
  fetch(`${workflowApiRoot}create-manager`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(managerInfo),
  })
    .then((response) => response.json())
    .then((results) => {
      //  console.log("Create Manager Response", results);
      if (results.status !== "success") {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (results.status === "success") {
        dispatch(getLinkedPropertyManagers(ItemLabel, userId));
        //     dispatch(setSaveStatus(`${ItemLabel}-success`));
      }
    });
};

// Update property manager

export const updateManager =
  (managerInfo, managerId, ItemLabel, userId) => (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));
    fetch(`${dataApiRoot}propertymanager/${managerId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(managerInfo),
    }).then(function (response) {
      //   console.log(response.status);
      if (response.status !== 204) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 204) {
        dispatch(getLinkedPropertyManagers(ItemLabel, userId));
      }
    });
  };

export const getLinkedManagementCompany = (ItemLabel, userId) => (dispatch) => {
  dispatch(setSaveStatus(`${ItemLabel}-fetching`));
  fetch(
    `${dataApiRoot}managementcompany?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22}]`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      //  console.log("Get Management Company Response", data);
      if (data) {
        dispatch(setLinkedManagementCompany(data.response.results));
        dispatch(setSaveStatus(`${ItemLabel}-success`));
      } else {
        dispatch(setResponseData(data));
      }
    });
};

// Get property id from vanity id

export const getPropertyId = (vanityPropertyId) => (dispatch) => {
  fetch(`${workflowApiRoot}get-propertyid`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify({ vanityPropertyId: vanityPropertyId }),
  })
    .then((response) => response.json())
    .then((results) => {
      // console.log("Get Property Id", results.response.propertyId);
      if (results.status !== "success") {
        //  dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (results.status === "success") {
        dispatch(setPropertyId(true, results.response.propertyId));
        dispatch(autoLogin());
        //     dispatch(setSaveStatus(`${ItemLabel}-success`));
      }
    });
};

// Get Linked Properties

export const getLinkedProperties = (ItemLabel, userId) => (dispatch) => {
  dispatch(setSaveStatus(`${ItemLabel}-fetching`));

  var payLoad = {
    linkedProperties: null,
  };

  // Paginated API Request for Properties
  const getPropertyData = async function (cursor) {
    let apiUrl = `${dataApiRoot}property?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22},{%22key%22:%22isarchived%22,%22constraint_type%22:%22equals%22,%22value%22:%22false%22}]&sort_field=modifieddate&descending=true&cursor=${cursor}`;
    var apiResults = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }).then((resp) => {
      return resp.json();
    });
    return apiResults;
  };

  const getAllPropertyData = async function (cursor = 0) {
    const data = await getPropertyData(cursor);
    const { remaining, results } = data.response;

    if (remaining > 0) {
      cursor += results.length;
      return results.concat(await getAllPropertyData(cursor));
    } else {
      return results;
    }
  };

  (async () => {
    const entirePropertyList = await getAllPropertyData();
    console.log(entirePropertyList);
    payLoad.linkedProperties = entirePropertyList;
    // console.log("payLoad.linkedProperties", payLoad.linkedProperties);

    if (payLoad.linkedProperties) {
      dispatch(setLinkedProperties(payLoad.linkedProperties));
      dispatch(setSaveStatus(`${ItemLabel}-success`));
    } else {
      dispatch(setResponseData("error"));
    }
  })();
};

// Creates a new property

export const createProperty =
  (propertyInfo, ItemLabel, userId) => (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-checking-id`));
    fetch(`${workflowApiRoot}check-property-id`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(propertyInfo),
    })
      .then((response) => response.json())
      .then((results) => {
        //  console.log("Check Property Id Response", results);

        if (!results.response.propertyIdAvailable) {
          dispatch(setSaveStatus(`${ItemLabel}-error`));
        }

        if (results.response.propertyIdAvailable) {
          dispatch(setSaveStatus(`${ItemLabel}-fetching`));
          dispatch(setLinkedPropertiesLoaded(false));

          fetch(`${workflowApiRoot}create-property/initializeuser`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify(propertyInfo),
          })
            .then((response) => response.json())
            .then((results) => {
              // console.log("Create Property Response", results.response.propertyId);
              if (results.status !== "success") {
                dispatch(setSaveStatus(`${ItemLabel}-error`));
              }
              if (results.status === "success") {
                dispatch(setPropertyId(true, results.response.propertyId));
                dispatch(getLinkedProperties(ItemLabel, userId));
                dispatch(setSaveStatus(`${ItemLabel}-success`));
              }
            });
        }
      });
  };

// This function updates property information in the database
// and sets response state for InfoItem components, controlled by the ItemLabel

export const updateProperty =
  (propertyInfo, propertyId, ItemLabel) => (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));
    fetch(`${dataApiRoot}property/${propertyId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(propertyInfo),
    }).then(function (response) {
      //  console.log(response.status);
      if (response.status !== 204) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 204) {
        //  dispatch(autoLogin(ItemLabel));
        dispatch(getSinglePropertyData(propertyId, ItemLabel));
      }
    });
  };

// updateContent

export const updateContent =
  (
    contentInfo,
    contentId,
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    userId,
    imageFilename,
    imageFile,
    videoFilename,
    videoFile,
    documentFilename,
    documentFile,
    subContent,
    linkedProperties
  ) =>
  (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    // imageFilePromise
    var imageFilePromise = new Promise((resolve, reject) => {
      if (imageFile) {
        fetch(`${storageRootUrl}/airguide/user/${userId}/${imageFilename}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream",
            AccessKey: `${storageApiKey}`,
            Accept: "application/octet-stream",
          },
          body: imageFile,
        })
          .then((response) => response.json())
          .then((data) => {
            //   console.log("uploadImageFile data", data.HttpCode);
            if (data.HttpCode === 201) {
              dispatch(setFileLoadStatus(`${imageFilename}-loaded`));
              resolve();
            }
            if (data.HttpCode !== 201) {
              dispatch(setFileLoadStatus(`${imageFilename}-error`));
              reject("Error in imageFilePromise");
            }
          });
      }
      if (!imageFile) {
        resolve();
      }
    });
    // End imageFilePromise

    // videoFilePromise
    var videoFilePromise = new Promise((resolve, reject) => {
      if (videoFile) {
        fetch(`${storageRootUrl}/airguide/user/${userId}/${videoFilename}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream",
            AccessKey: `${storageApiKey}`,
            Accept: "application/octet-stream",
          },
          body: videoFile,
        })
          .then((response) => response.json())
          .then((data) => {
            //    console.log("uploadVideoFile data", data.HttpCode);
            if (data.HttpCode === 201) {
              dispatch(setFileLoadStatus(`${videoFilename}-loaded`));
              resolve();
            }
            if (data.HttpCode !== 201) {
              dispatch(setFileLoadStatus(`${videoFilename}-error`));
              reject("Error in videoFilePromise");
            }
          });
      }
      if (!videoFile) {
        resolve();
      }
    });
    // End videoFilePromise

    // documentFilePromise
    var documentFilePromise = new Promise((resolve, reject) => {
      if (documentFile) {
        fetch(`${storageRootUrl}/airguide/user/${userId}/${documentFilename}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream",
            AccessKey: `${storageApiKey}`,
            Accept: "application/octet-stream",
          },
          body: documentFile,
        })
          .then((response) => response.json())
          .then((data) => {
            //   console.log("uploadDocumentFile data", data.HttpCode);
            if (data.HttpCode === 201) {
              dispatch(setFileLoadStatus(`${documentFilename}-loaded`));
              resolve();
            }
            if (data.HttpCode !== 201) {
              dispatch(setFileLoadStatus(`${documentFilename}-error`));
              reject("Error in documentFilePromise");
            }
          });
      }
      if (!documentFile) {
        resolve();
      }
    });
    // End documentFilePromise

    // Promise.all
    Promise.all([imageFilePromise, videoFilePromise, documentFilePromise]).then(
      () => {
        //   console.log("allPromises Resolved!");
        // Update content call
        fetch(`${dataApiRoot}customcontent/${contentId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify(contentInfo),
        }).then(function (response) {
          //  console.log(response.status);
          if (response.status !== 204) {
            dispatch(setSaveStatus(`${ItemLabel}-error`));
          }
          if (response.status === 204) {
            if (!subContent) {
              if (propertyId) {
                dispatch(updatePropertyModifiedDate(propertyId, contentId));
                dispatch(getCustomContent(propertyId, ItemLabel));
              }
              if (SharedContentProfileId) {
                dispatch(getSharedContent(SharedContentProfileId, ItemLabel));
              }
            }

            if (subContent) {
              dispatch(
                updateSubContent(
                  ItemLabel,
                  userId,
                  propertyId,
                  SharedContentProfileId,
                  subContent,
                  contentId,
                  linkedProperties
                )
              );
            }
          }
        });
        // End updateContent call
      }
    );
    // End Promise.all
  };
// End updateContent function

// updateSubContent - handles updating and creation of subContent items

export const updateSubContent =
  (
    ItemLabel,
    userId,
    propertyId,
    sharedContentProfileId,
    subContent,
    parentContentId,
    linkedProperties
  ) =>
  (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    const updateItems = subContent
      ? subContent.filter((item) => item.action === "update")
      : null;

    const deleteItems = subContent
      ? subContent.filter((item) => item.action === "delete" && item._id)
      : null;

    var createItems = subContent
      ? subContent.filter(
          (item) =>
            item.action === "create" &&
            (item.DetailText ||
              item.ButtonLink ||
              item.ButtonLabel ||
              item.InternalLink ||
              item.InternalLinkLabel ||
              item.DetailImage ||
              item.DetailVideo ||
              item.DetailDocument)
        )
      : null;

    // If this is a new parentContent Item it will receive parentContentId from createContent
    if (parentContentId) {
      const createItemsCopy = createItems.map((item) => {
        let listItem = Object.assign({}, item);
        listItem.ParentContent = parentContentId;
        return listItem;
      });
      createItems = createItemsCopy;
    }

    const fileUploadItems = subContent
      ? subContent.filter(
          (item) =>
            item.fileData &&
            (item.action === "update" || item.action === "create")
        )
      : null;

    //console.log("updateItems", updateItems)
    //console.log("deleteItems", deleteItems)
    //console.log("createItems", createItems)
    //console.log("fileUploadItems", fileUploadItems)

    var uploadFilesPromise = new Promise((resolve) => {
      if (fileUploadItems.length < 1) {
        resolve();
        // console.log("uploadFilesPromise resolved without running");
      }
      if (fileUploadItems.length > 0) {
        fileUploadItems.forEach((item, index, fileUploadItems) =>
          // dispatch(setFileLoadStatus(`${item.fileName}-loading`));
          fetch(`${storageRootUrl}/airguide/user/${userId}/${item.fileName}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/octet-stream",
              AccessKey: `${storageApiKey}`,
              Accept: "application/octet-stream",
            },
            body: item.fileData,
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("uploadFile data", data.HttpCode);
              if (data.HttpCode === 201) {
                setTimeout(function () {
                  dispatch(setFileLoadStatus(`${item.fileName}-loaded`));
                }, 2500);
              }
              if (data.HttpCode !== 201) {
                //reject();
                //dispatch(setFileLoadStatus(`${item.fileName}-error`));
              }
              if (index === fileUploadItems.length - 1) resolve();
              // console.log("uploadFilesPromise resolved after running");
            })
        );
      }
    });

    var createItemsPromise = new Promise((resolve) => {
      if (createItems.length < 1) {
        //console.log("createItems promise resolved without running");
        resolve();
      }
      if (createItems.length > 0) {
        //console.log("createItems detected");
        createItems.forEach((item, index, createItems) =>
          fetch(`${dataApiRoot}customcontent`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              LinkedProperty: item.isComponentShared
                ? linkedProperties
                : [propertyId],
              LinkedSharedContentProfile: item.isComponentShared
                ? sharedContentProfileId
                : null,
              LinkedMasterUser: userId,
              ContentType: item.ContentType,
              ContentVersion: item.ContentVersion ? item.ContentVersion + 1 : 1,
              ListRank: item.ListRank,
              ParentContent: item.ParentContent,
              DetailText: item.DetailText ? item.DetailText : "",
              ButtonLink: item.ButtonLink ? item.ButtonLink : "",
              ButtonLabel: item.ButtonLabel ? item.ButtonLabel : "",
              InternalLink: item.InternalLink ? item.InternalLink : "",
              InternalLinkLabel: item.InternalLinkLabel
                ? item.InternalLinkLabel
                : "",
              DetailImage: item.DetailImage ? item.DetailImage : "",
              DetailVideo: item.DetailVideo ? item.DetailVideo : "",
              DetailDocument: item.DetailDocument ? item.DetailDocument : "",
            }),
          }).then(function (response) {
            //console.log("createSubContent", response);
            if (response.status !== 201) {
              // dispatch(setSaveStatus(`${ItemLabel}-error`));
              //reject();
            }
            if (response.status === 201) {
              // dispatch(updatePropertyModifiedDate(propertyId, contentId));
              // dispatch(getCustomContent(propertyId, ItemLabel));
            }
            if (index === createItems.length - 1) resolve();
            //console.log("createItems promise resolved after running");
          })
        );
      }
    });

    var updateItemsPromise = new Promise((resolve) => {
      if (updateItems.length < 1) {
        // console.log("updateItems resolved without running")
        resolve();
      }
      if (updateItems.length > 0) {
        updateItems.forEach((item, index, updateItems) =>
          fetch(`${dataApiRoot}customcontent/${item._id}`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              DetailText: item.DetailText ? item.DetailText : "",
              ButtonLink: item.ButtonLink ? item.ButtonLink : "",
              ButtonLabel: item.ButtonLabel ? item.ButtonLabel : "",
              InternalLink: item.InternalLink ? item.InternalLink : "",
              InternalLinkLabel: item.InternalLinkLabel
                ? item.InternalLinkLabel
                : "",
              DetailImage: item.DetailImage ? item.DetailImage : "",
              DetailVideo: item.DetailVideo ? item.DetailVideo : "",
              DetailDocument: item.DetailDocument ? item.DetailDocument : "",
              ListRank: item.ListRank,
            }),
          }).then(function (response) {
            // console.log("updateSubContent", response);
            if (response.status !== 201) {
              // dispatch(setSaveStatus(`${ItemLabel}-error`));
              // reject();
            }
            if (response.status === 201) {
            }
            if (index === updateItems.length - 1) resolve();
            // console.log("updateItems resolved after running")
          })
        );
      }
    });

    var deleteItemsPromise = new Promise((resolve) => {
      if (deleteItems.length < 1) {
        resolve();
        // console.log("deleteItemsPromise resolved without running")
      }
      if (deleteItems.length > 0) {
        deleteItems.forEach((item, index, deleteItems) =>
          fetch(`${dataApiRoot}customcontent/${item._id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }).then(function (response) {
            // console.log("updateSubContent", response);
            if (response.status !== 201) {
              //dispatch(setSaveStatus(`${ItemLabel}-error`));
              //reject();
            }
            if (response.status === 201) {
            }
            if (index === deleteItems.length - 1) resolve();
            // console.log("deleteItemsPromise resolved without running")
          })
        );
      }
    });

    Promise.all([
      uploadFilesPromise,
      createItemsPromise,
      updateItemsPromise,
      deleteItemsPromise,
    ]).then(() => {
      // console.log("All done!");
      if (propertyId) {
        //  console.log("getCustomContent", propertyId, ItemLabel)
        dispatch(getCustomContent(propertyId, ItemLabel));
      }
      if (sharedContentProfileId) {
        dispatch(getSharedContent(sharedContentProfileId, ItemLabel));
      }
      // Delete all files no longer needed
      subContent.forEach((item) => {
        dispatch(deleteUploadedFile(item.fileDeleteUrl));
      });
    });
  };
// End updateSubContent function

// deleteSubContent - handles deletion of subContent items

export const deleteSubContent =
  (ItemLabel, propertyId, sharedContentProfileId, subContent) => (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    var deleteItems = subContent;

    // Add fileDeleteUrl in to all items
    if (subContent) {
      const cdnRootString = `${cdnRootUrl}/`;
      const deleteItemsCopy = deleteItems.map((item) => {
        const detailFile = item.DetailImage
          ? item.DetailImage
          : item.DetailVideo
          ? item.DetailVideo
          : item.DetailDocument
          ? item.DetailDocument
          : null;
        const cdnStorageString = detailFile
          ? `${detailFile.slice(
              detailFile.indexOf(cdnRootString) + cdnRootString.length
            )}`
          : null;
        const deleteFileString = detailFile
          ? detailFile.split("/")[0] === "https:"
            ? cdnStorageString
            : `https:${detailFile}`
          : null;

        let listItem = Object.assign({}, item);
        listItem.fileDeleteUrl = deleteFileString;
        return listItem;
      });
      deleteItems = deleteItemsCopy;
    }

    //console.log("deleteItems", deleteItems)

    var deleteSubContentItemsPromise = new Promise((resolve) => {
      if (deleteItems.length < 1) {
        resolve();
        // console.log("deleteSubContentItemsPromise resolved without running")
      }
      if (deleteItems.length > 0) {
        deleteItems.forEach((item, index, deleteItems) =>
          fetch(`${dataApiRoot}customcontent/${item._id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }).then(function (response) {
            // console.log("deleteSubContent", response);
            if (response.status !== 201) {
              //dispatch(setSaveStatus(`${ItemLabel}-error`));
              //reject();
            }
            if (response.status === 201) {
            }
            if (index === deleteItems.length - 1) resolve();
            // console.log("deleteSubContentItemsPromise resolved without running")
          })
        );
      }
    });

    Promise.all([deleteSubContentItemsPromise]).then(() => {
      // console.log("All done!");
      if (propertyId) {
        //  console.log("getCustomContent", propertyId, ItemLabel)
        dispatch(getCustomContent(propertyId, ItemLabel));
      }
      if (sharedContentProfileId) {
        dispatch(getSharedContent(sharedContentProfileId, ItemLabel));
      }
      // Delete all files no longer needed
      deleteItems.forEach((item) => {
        dispatch(deleteUploadedFile(item.fileDeleteUrl));
      });
    });
  };
// End deleteSubContent function

// Upload file to storage

export const uploadFile = (userId, fileName, file) => (dispatch) => {
  if (fileName !== "null") {
    dispatch(setFileLoadStatus(`${fileName}-loading`));
    fetch(`${storageRootUrl}/airguide/user/${userId}/${fileName}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
        AccessKey: `${storageApiKey}`,
        Accept: "application/octet-stream",
      },
      body: file,
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("uploadFile data", data.HttpCode);
        if (data.HttpCode === 201) {
          setTimeout(function () {
            dispatch(setFileLoadStatus(`${fileName}-loaded`));
          }, 2500);
        }
        if (data.HttpCode !== 201) {
          dispatch(setFileLoadStatus(`${fileName}-error`));
        }
      });
  }
};

// Delete uploaded file

export const deleteUploadedFile = (fileUrl) => (dispatch) => {
  // console.log("deleteUploadFileUrl", fileUrl)

  if (fileUrl && fileUrl.split("/")[0] === "https:") {
    fetch(`${workflowApiRoot}delete-uploaded-file`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ fileUrl: fileUrl }),
    });
  }

  //if (fileUrl.split("/")[0] !== "https:") {
  if (fileUrl && fileUrl.split("/")[0] === "user") {
    fetch(`${storageRootUrl}/airguide/${fileUrl}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/octet-stream",
        AccessKey: `${storageApiKey}`,
        Accept: "application/octet-stream",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //    console.log("deleteFile data", data);
      });
  }
};

// createContent

export const createContent =
  (
    contentInfo,
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    userId,
    imageFilename,
    imageFile,
    videoFilename,
    videoFile,
    documentFilename,
    documentFile,
    subContent
  ) =>
  (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    // imageFilePromise
    var imageFileCreatePromise = new Promise((resolve, reject) => {
      if (imageFile) {
        fetch(`${storageRootUrl}/airguide/user/${userId}/${imageFilename}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream",
            AccessKey: `${storageApiKey}`,
            Accept: "application/octet-stream",
          },
          body: imageFile,
        })
          .then((response) => response.json())
          .then((data) => {
            //   console.log("uploadImageFile data", data.HttpCode);
            if (data.HttpCode === 201) {
              dispatch(setFileLoadStatus(`${imageFilename}-loaded`));
              resolve();
            }
            if (data.HttpCode !== 201) {
              dispatch(setFileLoadStatus(`${imageFilename}-error`));
              reject("Error in imageFilePromise");
            }
          });
      }
      if (!imageFile) {
        resolve();
      }
    });
    // End imageFilePromise

    // videoFilePromise
    var videoFileCreatePromise = new Promise((resolve, reject) => {
      if (videoFile) {
        fetch(`${storageRootUrl}/airguide/user/${userId}/${videoFilename}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream",
            AccessKey: `${storageApiKey}`,
            Accept: "application/octet-stream",
          },
          body: videoFile,
        })
          .then((response) => response.json())
          .then((data) => {
            //   console.log("uploadVideoFile data", data.HttpCode);
            if (data.HttpCode === 201) {
              dispatch(setFileLoadStatus(`${videoFilename}-loaded`));
              resolve();
            }
            if (data.HttpCode !== 201) {
              dispatch(setFileLoadStatus(`${videoFilename}-error`));
              reject("Error in videoFilePromise");
            }
          });
      }
      if (!videoFile) {
        resolve();
      }
    });
    // End videoFilePromise

    // documentFilePromise
    var documentFileCreatePromise = new Promise((resolve, reject) => {
      if (documentFile) {
        fetch(`${storageRootUrl}/airguide/user/${userId}/${documentFilename}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream",
            AccessKey: `${storageApiKey}`,
            Accept: "application/octet-stream",
          },
          body: documentFile,
        })
          .then((response) => response.json())
          .then((data) => {
            //   console.log("uploadDocumentFile data", data.HttpCode);
            if (data.HttpCode === 201) {
              dispatch(setFileLoadStatus(`${documentFilename}-loaded`));
              resolve();
            }
            if (data.HttpCode !== 201) {
              dispatch(setFileLoadStatus(`${documentFilename}-error`));
              reject("Error in documentFilePromise");
            }
          });
      }
      if (!documentFile) {
        resolve();
      }
    });
    // End documentFilePromise

    // Promise.all
    Promise.all([
      imageFileCreatePromise,
      videoFileCreatePromise,
      documentFileCreatePromise,
    ]).then(() => {
      //  console.log("allPromises Resolved!");
      // Create content call
      fetch(`${dataApiRoot}customcontent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(contentInfo),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("createContent data", data);
          if (data.status !== "success") {
            dispatch(setSaveStatus(`${ItemLabel}-error`));
          }
          if (data.status === "success") {
            if (!subContent) {
              if (propertyId) {
                dispatch(updatePropertyModifiedDate(propertyId, data.id));
                dispatch(getCustomContent(propertyId, ItemLabel));
              }
              if (SharedContentProfileId) {
                dispatch(getSharedContent(SharedContentProfileId, ItemLabel));
              }
            }

            if (subContent) {
              const parentContentId = data.id;
              dispatch(
                updateSubContent(
                  ItemLabel,
                  userId,
                  propertyId,
                  SharedContentProfileId,
                  subContent,
                  parentContentId
                )
              );
            }
          }
        });
      // End updateContent call
    });
    // End Promise.all
  };
// End createContent function

// Start deleteContent function

export const deleteContent =
  (
    contentId,
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    popUpClose,
    subContent,
    userId
  ) =>
  (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    // Delete content call
    fetch(`${dataApiRoot}customcontent/${contentId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }).then(function (response) {
      console.log(response.status);
      if (response.status !== 204) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 204) {
        if (!subContent) {
          if (propertyId) {
            dispatch(getCustomContent(propertyId, ItemLabel, popUpClose));
            dispatch(updatePropertyModifiedDate(propertyId, contentId));
          }
          if (SharedContentProfileId) {
            dispatch(getSharedContent(SharedContentProfileId, ItemLabel));
          }
        }
        if (subContent) {
          dispatch(
            deleteSubContent(
              ItemLabel,
              propertyId,
              SharedContentProfileId,
              subContent
            )
          );
        }
        if (popUpClose) {
          popUpClose();
        }
      }
    });
    // End Delete content call
  };
// End deleteContent function

// Attach shared content to property

export const attachContent =
  (contentInfo, contentId, ItemLabel, propertyId, SharedContentProfileId) =>
  (dispatch) => {
    dispatch(setAttachContentLoaded(`${ItemLabel}-updating`));
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));
    fetch(`${workflowApiRoot}attach-content`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(contentInfo),
    }).then(function (response) {
      //  console.log(response.status);
      if (response.status !== 200) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 200) {
        dispatch(updatePropertyModifiedDate(propertyId, contentId));
        dispatch(getCustomContent(propertyId, ItemLabel));
        dispatch(getSharedContent(SharedContentProfileId, ItemLabel));
      }
    });
  };

// Detach shared content to property

export const detachContent =
  (contentInfo, contentId, ItemLabel, propertyId, SharedContentProfileId) =>
  (dispatch) => {
    dispatch(setAttachContentLoaded(`${ItemLabel}-updating`));
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));
    fetch(`${workflowApiRoot}detach-content`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(contentInfo),
    }).then(function (response) {
      //  console.log(response.status);
      if (response.status !== 200) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 200) {
        dispatch(updatePropertyModifiedDate(propertyId, contentId));
        dispatch(getCustomContent(propertyId, ItemLabel));
        dispatch(getSharedContent(SharedContentProfileId, ItemLabel));
      }
    });
  };

export const addLocalContent =
  (ItemLabel, propertyId, sharedContentProfileId, addItems) => (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    var addItemsPromise = new Promise((resolve, reject) => {
      if (addItems) {
        addItems.forEach((item, index, addItems) =>
          fetch(`${dataApiRoot}customcontent`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify(item),
          })
            .then((response) => response.json())

            .then((data) => {
              //    console.log("addLocalContent data", data);
              if (data.status !== "success") {
                dispatch(setSaveStatus(`${ItemLabel}-error`));
                reject();
              }
              if (data.status === "success") {
                dispatch(updatePropertyModifiedDate(propertyId, data.id));

                // if shared & linkedProperties then for each linked property attach id to content
              }
              if (index === addItems.length - 1) resolve();
            })
        );
      }
      if (!addItems) {
        resolve();
      }
    });
    addItemsPromise.then(() => {
      //   console.log("addItemsPromise Resolved!");

      if (propertyId) {
        dispatch(getCustomContent(propertyId, ItemLabel));
      }
      if (sharedContentProfileId) {
        dispatch(getSharedContent(sharedContentProfileId, ItemLabel));
      }
    });
  };

export const updateLocalContent =
  (ItemLabel, propertyId, sharedContentProfileId, updateItems) =>
  (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    var updateItemsPromise = new Promise((resolve, reject) => {
      updateItems.forEach((item, index, updateItems) =>
        fetch(`${dataApiRoot}customcontent/${item.ContentID}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify(item),
        }).then(function (response) {
          //   console.log("updateLocalContent", response);
          if (response.status !== 204) {
            dispatch(setSaveStatus(`${ItemLabel}-error`));
            reject();
          }
          if (response.status === 204) {
            // dispatch(updatePropertyModifiedDate(propertyId, contentId));
            // dispatch(getCustomContent(propertyId, ItemLabel));
          }
          if (index === updateItems.length - 1) resolve();
        })
      );
    });
    updateItemsPromise.then(() => {
      // console.log("All done!");
      if (propertyId) {
        dispatch(getCustomContent(propertyId, ItemLabel));
      }
      if (sharedContentProfileId) {
        dispatch(getSharedContent(sharedContentProfileId, ItemLabel));
      }
    });
  };

export const deleteLocalContent =
  (ItemLabel, propertyId, sharedContentProfileId, deleteItems) =>
  (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    var deleteItemsPromise = new Promise((resolve, reject) => {
      deleteItems.forEach((item, index, deleteItems) =>
        fetch(`${dataApiRoot}customcontent/${item._id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }).then(function (response) {
          //    console.log("deleteLocalContent", response);
          if (response.status !== 204) {
            dispatch(setSaveStatus(`${ItemLabel}-error`));
            reject();
          }
          if (response.status === 204) {
            // dispatch(updatePropertyModifiedDate(propertyId, contentId));
            // dispatch(getCustomContent(propertyId, ItemLabel));
          }
          if (index === deleteItems.length - 1) resolve();
        })
      );
    });
    deleteItemsPromise.then(() => {
      // console.log("All done!");
      if (propertyId) {
        dispatch(getCustomContent(propertyId, ItemLabel));
      }
      if (sharedContentProfileId) {
        dispatch(getSharedContent(sharedContentProfileId, ItemLabel));
      }
    });
  };

export const updateListRank =
  (ItemLabel, propertyId, sharedContentProfileId, updateItems) =>
  (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    var updateListRankPromise = new Promise((resolve, reject) => {
      updateItems.forEach((item, index, updateItems) =>
        fetch(`${dataApiRoot}customcontent/${item._id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ ListRank: index }),
        }).then(function (response) {
          //   console.log("updateListRank", response);
          if (response.status !== 204) {
            dispatch(setSaveStatus(`${ItemLabel}-error`));
            reject();
          }
          if (response.status === 204) {
            // console.log("response", response);
            // dispatch(updatePropertyModifiedDate(propertyId, contentId));
            // dispatch(getCustomContent(propertyId, ItemLabel));
          }
          if (index === updateItems.length - 1) resolve();
        })
      );
    });
    updateListRankPromise.then(() => {
      // console.log("All done!");
      if (propertyId) {
        dispatch(getCustomContent(propertyId, ItemLabel));
      }
      if (sharedContentProfileId) {
        dispatch(getSharedContent(sharedContentProfileId, ItemLabel));
      }
    });
  };

export const createLocalContentProfile =
  (payload, ItemLabel, userId) => (dispatch) => {
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));
    fetch(`${dataApiRoot}sharedcontentprofile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    }).then(function (response) {
      //  console.log("createLocalContentProfile response", response.status);
      if (response.status !== 201) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 201) {
        //  dispatch(autoLogin(ItemLabel));
        //   dispatch(getSinglePropertyData(propertyId, ItemLabel));
        dispatch(getSharedContentProfiles(userId, ItemLabel));
      }
    });
  };

export const attachLocalContentProfile =
  (
    ItemLabel,
    userId,
    propertyId,
    sharedContentProfileId,
    contentProfileItems
  ) =>
  (dispatch) => {
    dispatch(setSharedProfilesLoaded(`${ItemLabel}-updating`));
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    fetch(`${workflowApiRoot}attach-content-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(contentProfileItems),
    }).then(function (response) {
      //  console.log("attachLocalContentProfile", response);
      if (response.status !== 200) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 200) {
        // dispatch(updatePropertyModifiedDate(propertyId, contentId));
        dispatch(getCustomContent(propertyId, ItemLabel));
        dispatch(getSharedContent(sharedContentProfileId, ItemLabel));
        dispatch(getSharedContentProfiles(userId, ItemLabel));
      }
    });
  };

export const detachLocalContentProfile =
  (
    ItemLabel,
    userId,
    propertyId,
    sharedContentProfileId,
    contentProfileItems
  ) =>
  (dispatch) => {
    dispatch(setSharedProfilesLoaded(`${ItemLabel}-updating`));
    dispatch(setSaveStatus(`${ItemLabel}-fetching`));

    fetch(`${workflowApiRoot}detach-content-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(contentProfileItems),
    }).then(function (response) {
      // console.log("detachLocalContentProfile response", response)
      if (response.status !== 200) {
        dispatch(setSaveStatus(`${ItemLabel}-error`));
      }
      if (response.status === 200) {
        // dispatch(updatePropertyModifiedDate(propertyId, contentId));
        //  dispatch(getCustomContent(propertyId, ItemLabel));
        //  dispatch(getSharedContent(sharedContentProfileId, ItemLabel));
        dispatch(getSharedContentProfiles(userId, ItemLabel));
      }
    });
  };

// Update the linked property when content is modified
export const updatePropertyModifiedDate =
  (propertyId, contentId) => (dispatch) => {
    fetch(`${workflowApiRoot}property-modified`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ propertyId: propertyId, contentId: contentId }),
    });
  };

// Updates user email via workflow API

export const updateUserEmail = (userInfo, ItemLabel) => (dispatch) => {
  dispatch(setSaveStatus(`${ItemLabel}-fetching`));
  fetch(`${workflowApiRoot}update-user-email/initializeuser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(userInfo),
  }).then(function (response) {
    //  console.log(response.status);
    if (response.status !== 200) {
      dispatch(setSaveStatus(`${ItemLabel}-error`));
    }
    if (response.status === 200) {
      dispatch(autoLogin(ItemLabel));
    }
  });
};

// Updates user password via workflow API

export const updateUserPassword = (userInfo, ItemLabel) => (dispatch) => {
  dispatch(setSaveStatus(`${ItemLabel}-fetching`));
  fetch(`${workflowApiRoot}updateuserpassword/initializeuser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(userInfo),
  }).then(function (response) {
    //   console.log(response.status);
    if (response.status !== 200) {
      dispatch(setSaveStatus(`${ItemLabel}-error`));
    }
    if (response.status === 200) {
      dispatch(setSaveStatus(`${ItemLabel}-success`));
    }
  });
};

export const updateUserTel = (payload, label, type) => (dispatch) => {
  // console.log("updateUserTel running");
  dispatch(setSaveStatus(`${label}-fetching`));
  fetch(`${workflowApiRoot}update-${type}-tel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  }).then(function (response) {
    // console.log(response);
    if (response.status !== 200) {
      dispatch(setSaveStatus(`${label}-error`));
    }
    if (response.status === 200) {
      //  console.log("autologin running")
      dispatch(autoLogin(label));
    }
  });
};
