import React from "react";
import "../styles/Breadcrumb.css";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

const Breadcrumb = (props) => {
  const {
    history,
    previousPagePath,
    previousPageTitle,
    PreviousPageTitle,
    PreviousPagePath,
  } = props;
  const previousPath = previousPagePath ? previousPagePath : PreviousPagePath;
  const previousTitle = previousPageTitle
    ? previousPageTitle
    : PreviousPageTitle;

  const handleBack = (e) => {
    const propertyId =
      props.location.state && props.location.state.propertyId
        ? props.location.state.propertyId
        : "";
    e.preventDefault();
    history.push(previousPath, { propertyId });
  };

  return (
    <div className="breadcrumb-container">
      {previousPath && (
        <div className="breadcrumb-chevron-icon-wrapper-left">
          <ChevronLeftIcon className="breadcrumb-chevron-icon" />
        </div>
      )}
      <div className="breadcrumb-previous-section" onClick={handleBack}>
        {previousTitle && (
          <div className="breadcrumb-link">{previousTitle}</div>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
