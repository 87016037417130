import React from "react";
import ContentSectionPage from "../pages/ContentSectionPage";
import Loading from "./Loading";
import { connect } from "react-redux";
import { getCustomContent } from "../redux/actions/userActions";
import { setCustomContentLoaded } from "../redux/actions/userActions";
import { getSharedContent } from "../redux/actions/userActions";
import { setSharedContentLoaded } from "../redux/actions/userActions";
import { getLinkedProperties } from "../redux/actions/userActions";
import { setLinkedPropertiesLoaded } from "../redux/actions/userActions";

class ContentSectionPageLoader extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const {
      PropertyId,
      CustomContentPropertyId,
      SharedContentLoadedId,
      SharedPropertyContentProfileId,
      ItemLabel,
    } = this.props;

    const currentPathname = window.location.pathname;
    const pathParts = currentPathname.split("/");
    const isComponentShared = pathParts[1] === "shared-content" ? true : false;

    if (this.props.location.state) {
      const propertyId = this.props.location.state.propertyId;
      //  console.log("Condition 1 - content not already loaded (from link)");

      if (!isComponentShared) {
        // Condition 1 - content not already loaded (from link)
        //  console.log("Condition 1 - content not already loaded (from link)");
        if (this.props.location.state.propertyId !== CustomContentPropertyId) {
          this.props.setCustomContentLoaded(false, CustomContentPropertyId);

          this.props.getCustomContent(propertyId);
          if (
            SharedPropertyContentProfileId &&
            SharedPropertyContentProfileId !== SharedContentLoadedId
          ) {
            this.props.setSharedContentLoaded(false, SharedContentLoadedId);
            this.props.getSharedContent(
              SharedPropertyContentProfileId,
              ItemLabel
            );
          }
          if (!SharedPropertyContentProfileId) {
            this.props.setSharedContentLoaded(true, "");
          }

          this.props.setLinkedPropertiesLoaded(true);
        }

        // Condition 2 - content is already loaded (from link)
        if (this.props.location.state.propertyId === CustomContentPropertyId) {
          if (
            SharedPropertyContentProfileId &&
            SharedPropertyContentProfileId !== SharedContentLoadedId
          ) {
            this.props.setSharedContentLoaded(false, SharedContentLoadedId);
            this.props.getSharedContent(
              SharedPropertyContentProfileId,
              ItemLabel
            );
          }
          if (!SharedPropertyContentProfileId) {
            this.props.setSharedContentLoaded(true, "");
          }
          this.props.setLinkedPropertiesLoaded(true);
          this.props.setCustomContentLoaded(true, CustomContentPropertyId);
        }
      }

      if (isComponentShared) {
        // Condition 1 - content not already loaded (from link)
        if (SharedPropertyContentProfileId !== SharedContentLoadedId) {
          this.props.setSharedContentLoaded(false, SharedContentLoadedId);
          this.props.getSharedContent(
            SharedPropertyContentProfileId,
            ItemLabel
          );
        }

        // Condition 2 - content is already loaded (from link)
        if (SharedPropertyContentProfileId === SharedContentLoadedId) {
          this.props.setSharedContentLoaded(true, SharedContentLoadedId);
        }
      }
    }

    // Condition 3 - page loaded in browser
    if (!this.props.location.state) {
      //  console.log("Condition 3 - page loaded in browser");

      if (!isComponentShared && PropertyId) {
        this.props.setCustomContentLoaded(false, CustomContentPropertyId);
        this.props.setSharedContentLoaded(false, SharedContentLoadedId);
        this.props.getCustomContent(PropertyId);
        this.props.setLinkedPropertiesLoaded(true);
        if (SharedPropertyContentProfileId) {
          this.props.getSharedContent(
            SharedPropertyContentProfileId,
            ItemLabel
          );
        }
        if (!SharedPropertyContentProfileId) {
          this.props.setSharedContentLoaded(true, "");
        }
      }
      if (isComponentShared && SharedPropertyContentProfileId) {
        this.props.setSharedContentLoaded(false, SharedContentLoadedId);
        this.props.getSharedContent(SharedPropertyContentProfileId, ItemLabel);
      }
    }
  };

  render() {
    const {
      SaveStatus,
      userData,
      companyData,
      managerData,
      propertyData,
      systemContent,
      systemCategories,
      customContent,
      CustomContentLoaded,
      PropertyId,
      linkedPropertiesLoaded,
      SharedContentLoaded,
      SharedPropertyContentProfileId,
      sharedContent,
    } = this.props;

    const currentPathname = window.location.pathname;
    const pathParts = currentPathname.split("/");
    const isComponentShared = pathParts[1] === "shared-content" ? true : false;

    const propertyId =
      this.props.location.state && this.props.location.state.propertyId
        ? this.props.location.state.propertyId
        : PropertyId;

    if (
      (!isComponentShared &&
        (!linkedPropertiesLoaded ||
          !CustomContentLoaded ||
          !PropertyId ||
          !propertyData)) ||
      !SharedContentLoaded ||
      (isComponentShared &&
        (!SharedContentLoaded || !SharedPropertyContentProfileId))
    ) {
      return <Loading LoadingCanvas={"loading-page-header-canvas"} />;
    }

    if (
      (!isComponentShared &&
        linkedPropertiesLoaded &&
        CustomContentLoaded &&
        PropertyId &&
        propertyData &&
        SharedContentLoaded) ||
      (isComponentShared &&
        SharedPropertyContentProfileId &&
        SharedContentLoaded)
    ) {
      return (
        <ContentSectionPage
          {...this.props}
          userData={userData}
          companyData={companyData}
          managerData={managerData}
          propertyData={propertyData}
          systemCategories={systemCategories}
          systemContent={systemContent}
          customContent={customContent}
          sharedContent={sharedContent}
          SaveStatus={SaveStatus}
          PropertyId={propertyId}
          isComponentShared={isComponentShared}
          SharedPropertyContentProfileId={SharedPropertyContentProfileId}
        />
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomContent: (propertyId) => dispatch(getCustomContent(propertyId)),
    setCustomContentLoaded: (payload, propertyId) =>
      dispatch(setCustomContentLoaded(payload, propertyId)),
    getSharedContent: (SharedPropertyContentProfileId) =>
      dispatch(getSharedContent(SharedPropertyContentProfileId)),
    setSharedContentLoaded: (payload, SharedContentLoadedId) =>
      dispatch(setSharedContentLoaded(payload, SharedContentLoadedId)),
    setLinkedPropertiesLoaded: (payload) =>
      dispatch(setLinkedPropertiesLoaded(payload)),
    getLinkedProperties: (ItemLabel, userId) =>
      dispatch(getLinkedProperties(ItemLabel, userId)),
  };
};

export default connect(null, mapDispatchToProps)(ContentSectionPageLoader);
