import React from "react";
import { connect } from "react-redux";
import { attachContent } from "../redux/actions/userActions";
import { detachContent } from "../redux/actions/userActions";
import { attachLocalContentProfile } from "../redux/actions/userActions";
import { detachLocalContentProfile } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import ContentPreviewPopup from "./ContentPreviewPopup";
import { workflowApiRoot } from "../constants.js";
import "../styles/InfoItem.css";
import "../styles/CheckBox.css";

class SharedContentSelector extends React.Component {
  state = {
    contentCount: 0,
    contentCountLoaded: false,
  };

  componentDidMount = () => {
    const { SharedContentProfileId, ContentType } = this.props;

    if (ContentType === "local-profile") {
      fetch(`${workflowApiRoot}get-profile-content-count`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ profileId: SharedContentProfileId }),
      })
        .then((response) => response.json())
        .then((results) => {
          if (results.status !== "success") {
            this.setState({ contentCount: 0, contentCountLoaded: true });
          }

          if (results.status === "success") {
            this.setState({
              contentCount: results.response.contentCount,
              contentCountLoaded: true,
            });
          }
        });
    }
  };

  onAdd = (e) => {
    const {
      ItemUniqueId,
      ContentId,
      PropertyId,
      SharedContentProfileId,
      UserId,
      ContentType,
      subContent,
    } = this.props;

    e.preventDefault();
    this.props.setSaveStatus(`${ItemUniqueId}-editing`);

    if (ContentType !== "local-profile") {
      const attachContentPayload = {
        contentId: ContentId,
        propertyId: PropertyId,
      };
      this.props.attachContent(
        attachContentPayload,
        ContentId,
        ItemUniqueId,
        PropertyId,
        SharedContentProfileId
      );

      if (subContent) {
        subContent.forEach((item) => {
          const attachSubContentPayload = {
            contentId: item._id,
            propertyId: PropertyId,
          };
          this.props.attachContent(
            attachSubContentPayload,
            item._id,
            ItemUniqueId,
            PropertyId,
            SharedContentProfileId
          );
        });
      }
    }

    if (ContentType === "local-profile") {
      const attachProfilePayload = {
        profileId: SharedContentProfileId,
        propertyId: PropertyId,
      };
      this.props.attachLocalContentProfile(
        ItemUniqueId,
        UserId,
        PropertyId,
        SharedContentProfileId,
        attachProfilePayload
      );
    }
  };

  onRemove = (e) => {
    const {
      ItemUniqueId,
      ContentId,
      UserId,
      PropertyId,
      SharedContentProfileId,
      ContentType,
      subContent,
    } = this.props;

    e.preventDefault();
    this.props.setSaveStatus(`${ItemUniqueId}-editing`);

    if (ContentType !== "local-profile") {
      const detachContentPayload = {
        contentId: ContentId,
        propertyId: PropertyId,
      };
      this.props.detachContent(
        detachContentPayload,
        ContentId,
        ItemUniqueId,
        PropertyId,
        SharedContentProfileId
      );
      if (subContent) {
        subContent.forEach((item) => {
          const detachSubContentPayload = {
            contentId: item._id,
            propertyId: PropertyId,
          };
          this.props.detachContent(
            detachSubContentPayload,
            item._id,
            ItemUniqueId,
            PropertyId,
            SharedContentProfileId
          );
        });
      }
    }

    if (ContentType === "local-profile") {
      const detachProfilePayload = {
        profileId: SharedContentProfileId,
        propertyId: PropertyId,
      };
      this.props.detachLocalContentProfile(
        ItemUniqueId,
        UserId,
        PropertyId,
        SharedContentProfileId,
        detachProfilePayload
      );
    }
  };

  render() {
    const {
      ItemHeading,
      ItemUniqueId,
      IsChecked,
      AttachContentLoaded,
      ItemData,
      ContentType,
    } = this.props;

    const ContentCount = this.state.contentCount;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={false}
                IsCustomContent={true}
                ItemHeading={ItemHeading}
                IsNewCustomItem={false}
              />

              {ContentType !== "local-profile" && (
                <>
                  <div>
                    <ContentPreviewPopup
                      ItemData={ItemData}
                      ButtonText={"View"}
                    />
                  </div>
                </>
              )}

              {ContentType === "local-profile" && (
                <>
                  <div className="local-profiles-count-text">
                    {!this.state.contentCountLoaded && "...loading"}
                    {this.state.contentCountLoaded &&
                      (ContentCount === 0 || ContentCount > 1) &&
                      `(${ContentCount} places)`}
                    {this.state.contentCountLoaded &&
                      ContentCount === 1 &&
                      `(${ContentCount} place)`}
                  </div>
                </>
              )}
            </div>
            <button
              className={
                AttachContentLoaded === `${ItemUniqueId}-updating`
                  ? "infoitem-edit-button-loading"
                  : !IsChecked
                  ? "infoitem-edit-button"
                  : "infoitem-edit-button-red"
              }
              onClick={IsChecked ? this.onRemove : this.onAdd}
            >
              {AttachContentLoaded === `${ItemUniqueId}-updating`
                ? "updating..."
                : !IsChecked
                ? "Add"
                : "Remove"}
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  attachContent: (
    contentInfo,
    contentId,
    ItemLabel,
    propertyId,
    SharedContentProfileId
  ) =>
    dispatch(
      attachContent(
        contentInfo,
        contentId,
        ItemLabel,
        propertyId,
        SharedContentProfileId
      )
    ),
  detachContent: (
    contentInfo,
    contentId,
    ItemLabel,
    propertyId,
    SharedContentProfileId
  ) =>
    dispatch(
      detachContent(
        contentInfo,
        contentId,
        ItemLabel,
        propertyId,
        SharedContentProfileId
      )
    ),
  attachLocalContentProfile: (
    ItemLabel,
    userId,
    propertyId,
    sharedContentProfileId,
    contentProfileItems
  ) =>
    dispatch(
      attachLocalContentProfile(
        ItemLabel,
        userId,
        propertyId,
        sharedContentProfileId,
        contentProfileItems
      )
    ),
  detachLocalContentProfile: (
    ItemLabel,
    userId,
    propertyId,
    sharedContentProfileId,
    contentProfileItems
  ) =>
    dispatch(
      detachLocalContentProfile(
        ItemLabel,
        userId,
        propertyId,
        sharedContentProfileId,
        contentProfileItems
      )
    ),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
});

export default connect(null, mapDispatchToProps)(SharedContentSelector);
