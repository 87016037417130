import React from "react";
import InfoItemCheckMark from "./InfoItemCheckMark";
import ContentEditorPopup from "./ContentEditorPopup";
import "../styles/PropertyDetailsSection.css";
import "../styles/InfoPage.css";
import "../styles/ContentItem.css";

const ContentListItem = (props) => {
  const {
    isRequired,
    sectionTitle,
    isChecked,
    itemData,
    targetType,
    categoryLabel,
    itemLabel,
    propertyId,
  } = props;

  const onCategoryEdit = (e) => {
    const { categoryTarget, propertyId } = props;
    e.preventDefault();
    props.history.push({
      pathname: categoryTarget,
      state: { propertyId: propertyId },
    });
  };

  return (
    <div className="content-list-item-container">
      <div className="section-break-wrapper">
        <div className="section-break"></div>
      </div>
      {targetType === "content" && (
        <div className="content-list-item-heading-and-button-container">
          <InfoItemCheckMark
            IsChecked={isChecked}
            IsRequired={isRequired}
            IsCustomContent={true}
            ItemHeading={sectionTitle}
          />

          <ContentEditorPopup
            buttonText={"Edit"}
            itemType={"edit"}
            itemData={itemData}
            categoryLabel={categoryLabel}
            itemLabel={itemLabel}
            propertyId={propertyId}
          />
        </div>
      )}

      {targetType === "category" && (
        <>
          <div className="report-checkitem-outer-wrapper">
            <div className="report-checkitem-wrapper">
              <div className="report-checkitem-description-wrapper">
                <div className="content-list-item-heading-and-button-container">
                  <InfoItemCheckMark
                    IsChecked={isChecked}
                    IsRequired={isRequired}
                    IsCustomContent={true}
                    ItemHeading={sectionTitle}
                  />
                </div>
                {itemData.CategoryShortDescription && (
                  <div className="infopage-text-and-button-wrapper">
                    <div className="infoitem-save-button-container-padding-left">
                      <div className="content-list-item-description">
                        {itemData.CategoryShortDescription}
                      </div>
                    </div>
                  </div>
                )}
                <button
                  className="checkitem-edit-button"
                  onClick={onCategoryEdit}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContentListItem;
