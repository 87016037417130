import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import NavigationHeader from "../components/NavigationHeader";
import SharedContentSelector from "../components/SharedContentSelector";
import ContentSection from "../components/ContentSection";
import "../styles/InfoPage.css";

const ContentSectionPage = (props) => {
  const { systemCategories, customCategories, userData } = useSelector(
    (state) => state.userReducer.user,
    shallowEqual
  );

  const userId = userData ? userData._id : "";

  const sharedContent = useSelector(
    (state) => state.userReducer.user.sharedCustomContent,
    shallowEqual
  );

  const { customContent } = props;

  const attachContentLoaded = useSelector(
    (state) => state.userReducer.attachContentLoaded,
    shallowEqual
  );

  const propertyId = useSelector(
    (state) => state.userReducer.propertyId,
    shallowEqual
  );

  const saveStatus = useSelector(
    (state) => state.fetchReducer.saveStatus,
    shallowEqual
  );

  const currentPathname = window.location.pathname;
  const pathParts = window.location.pathname.split("/");
  const previousPagePathLevelUp = currentPathname.replace(/\/[^\/]*$/, "");
  const previousPagePathLevelUpSplit = previousPagePathLevelUp.split("/");
  const previousPagePath =
    previousPagePathLevelUpSplit[previousPagePathLevelUpSplit.length - 1] ===
    "content"
      ? previousPagePathLevelUp.replace("/content", "")
      : previousPagePathLevelUp;
  const isShared = pathParts[1] === "shared-content" ? true : false;

  var categoryLabel =
    pathParts.length > 3 ? pathParts[pathParts.length - 1] : "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customCategoriesFiltered = isShared
    ? customCategories.filter((item) => item.LinkedSharedContentProfile)
    : customCategories.filter((item) => !item.LinkedSharedContentProfile);

  const allContentCategories = [
    ...customCategoriesFiltered,
    ...systemCategories,
  ];

  const currentCategory = allContentCategories.filter((item) => {
    return item.CategoryLabel === categoryLabel;
  });

  const currentPageTitle = currentCategory[0].CategoryTitle
    ? currentCategory[0].CategoryTitle
    : "";

  const parentCategoryLabel = currentCategory[0].ParentCategoryLabel
    ? currentCategory[0].ParentCategoryLabel
    : "";

  const parentCategory = allContentCategories.filter((item) => {
    return item.CategoryLabel === parentCategoryLabel;
  });

  const parentCategoryTitle =
    parentCategory[0] && parentCategory[0].CategoryTitle
      ? parentCategory[0].CategoryTitle
      : isShared
      ? "Shared Property Content"
      : "Property Content";

  //

  const sharedContentFiltered =
    sharedContent && sharedContent[0]
      ? sharedContent.filter((item) => {
          return item.CategoryLabel === categoryLabel;
        })
      : null;

  const sharedContentSubContent =
    sharedContent && sharedContent[0]
      ? sharedContent.filter((item) => {
          return item.ContentType === "sub-details";
        })
      : null;

  const checkIfChecked = (propertyId, itemId, data) => {
    var filter = data.filter((item) => {
      return (
        item._id === itemId &&
        item.LinkedProperty &&
        item.LinkedProperty.includes(propertyId)
      );
    });
    if (filter[0]) return true;
    if (!filter[0]) return false;
  };

  return (
    <>
      <NavigationHeader
        {...props}
        pathRoot={isShared ? "shared-content" : "property"}
        previousPageTitle={parentCategoryTitle}
        previousPagePath={previousPagePath}
        currentPageTitle={currentPageTitle}
      />
      <div className="canvas-with-header-navheader-and-footer">
        <div className="canvas-with-header-and-footer-inner-container">
          <div className="h1-header-large"> {currentPageTitle}</div>

          {!isShared && sharedContentFiltered && sharedContentFiltered[0] && (
            <>
              <div className="infoitem-container-box">
                <div className="infoitem-container-box-heading">
                  Shared Content
                </div>
                {sharedContentFiltered.map((item) => (
                  <SharedContentSelector
                    key={item._id}
                    ItemUniqueId={`shared-content-${item._id}`}
                    ContentId={item._id}
                    PropertyId={propertyId}
                    SharedContentProfileId={item.LinkedSharedContentProfile}
                    SaveStatus={saveStatus}
                    IsChecked={checkIfChecked(
                      propertyId,
                      item._id,
                      sharedContentFiltered
                    )}
                    ItemHeading={item.DetailTitle}
                    AttachContentLoaded={attachContentLoaded}
                    ItemData={item}
                    subContent={
                      sharedContentSubContent
                        ? sharedContentSubContent.filter((subItem) => {
                            return subItem.ParentContent === item._id;
                          })
                        : null
                    }
                    UserId={userId}
                  />
                ))}
              </div>
            </>
          )}

          <ContentSection {...props} customContent={customContent} />
        </div>
      </div>
    </>
  );
};

export default ContentSectionPage;
