import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import InfoItemSubContentItem from "./InfoItemSubContentItem";
import "../styles/InfoItem.css";
import "../styles/InfoItemSubContent.css";
import "../styles/CheckBox.css";

export default function InfoItemSubContent(props) {
  const {
    contentId,
    PropertyId,
    userId,
    DetailLabel,
    isComponentShared,
    SharedContentProfileId,
    subContent,
    onSubContentStateChange,
    onRemoveContentClick,
    resetKey,
    displayAllContent, // Added temp to support old and new shared content
  } = props;

  // Variables
  const customContent = useSelector(
    (state) => state.userReducer.user.linkedCustomContent,
    shallowEqual
  );

  const sharedContent = useSelector(
    (state) => state.userReducer.user.sharedCustomContent,
    shallowEqual
  );

  const contentSelected =
    isComponentShared && !displayAllContent ? sharedContent : customContent;

  const customContentFiltered = contentSelected
    ? contentSelected.filter(
        (item) =>
          item.ParentContent === contentId && item.ContentType === "sub-details"
      )
    : null;

  // Local functions
  const onAddContentClick = (type) => {
    const stateUpdate = [
      ...subContent,
      {
        type: type,
        action: "create",
        ContentType: "sub-details",
        ParentContent: contentId,
        isComponentShared: isComponentShared,
      },
    ];
    const stateUpdateListRank = stateUpdate.map((item, index) => {
      var listItem = Object.assign({}, item);
      listItem.ListRank = index + 1;
      return listItem;
    });
    onSubContentStateChange(stateUpdateListRank);
  };

  const onStateUpdate = (objectKey, objectValue, index) => {
    // 1. Make a shallow copy of the items
    let subContentCopy = [...subContent];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...subContent[index] };
    // 3. Replace the property you're intested in
    item[objectKey] = objectValue;
    // 4. Put it back into our array
    subContentCopy[index] = item;
    // 5. Set the state to our new copy
    // setSubContent([...subContentCopy]);
    onSubContentStateChange([...subContentCopy]);
  };

  // Lifecycle functions
  // Edit or Cancel clicked in InfoItemMulti updates resetKey variable triggering subContent to reset
  useEffect(() => {
    const stateUpdate = customContentFiltered;
    const stateUpdateList = stateUpdate.map((item) => {
      var listItem = Object.assign({}, item);
      listItem.action = "update";
      return listItem;
    });
    onSubContentStateChange(stateUpdateList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetKey]);

  // console.log("subContent", subContent);

  return (
    <>
      {subContent &&
        subContent.map((item, index) => (
          <div
            key={index}
            className={
              item.action === "update" || item.action === "create"
                ? "infoitem-form-inner-container-textarea"
                : ""
            }
          >
            {(item.action === "update" || item.action === "create") && (
              <InfoItemSubContentItem
                key={index}
                divKey={index}
                DetailLabel={DetailLabel}
                ItemUniqueId={`${DetailLabel}-${index}`}
                PropertyId={PropertyId}
                itemContent={item}
                itemIndex={index}
                onRemoveContentClick={onRemoveContentClick}
                onStateUpdate={onStateUpdate}
                isComponentShared={isComponentShared}
                SharedContentProfileId={SharedContentProfileId}
                userId={userId}
              />
            )}
          </div>
        ))}

      <div className="infoitem-subcontent-module-container">
        <label className="infoitem-form-label">Add more content</label>
        <div className="infoitem-subcontent-buttons-container">
          <div
            className="infoitem-subcontent-button"
            onClick={() => onAddContentClick("text")}
          >
            Text
          </div>
          <div
            className="infoitem-subcontent-button"
            onClick={() => onAddContentClick("image")}
          >
            Image
          </div>
          <div
            className="infoitem-subcontent-button"
            onClick={() => onAddContentClick("video")}
          >
            Video
          </div>
          <div
            className="infoitem-subcontent-button"
            onClick={() => onAddContentClick("pdf")}
          >
            PDF
          </div>
          <div
            className="infoitem-subcontent-button"
            onClick={() => onAddContentClick("button")}
          >
            Button
          </div>
          <div
            className="infoitem-subcontent-button"
            onClick={() => onAddContentClick("internal-link")}
          >
            Section Link
          </div>
        </div>
      </div>
    </>
  );
}
