import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "../styles/InfoItemMultiTextEditor.css";

const InfoItemMultiTextEditor = React.memo((props) => {
  const modules = {
    toolbar: [
      [{ header: [2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [
        {
          color: [
            "var(--UIcolour1)",
            "var(--UIcolour2)",
            "var(--UIcolour3)",
            "var(--UIcolour4)",
            "var(--UIcolour5)",
            "var(--brandColour3)",
            "var(--UIcolour0)",
          ],
        },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  const { htmlText, placeHolder, itemIndex } = props;
  const isInitialRender = useRef(false);

  const handleProcedureContentChange = (content, delta, source, editor) => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    props.onHtmlTextChange(content, itemIndex);
    // console.log("html text change content", content);
  };

  return (
    <div className="infoitem-text-editor-container">
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder={placeHolder}
        value={htmlText}
        onChange={handleProcedureContentChange}
      />
    </div>
  );
});

export default InfoItemMultiTextEditor;
