import React, { useEffect } from "react";
import ContentSection from "../components/ContentSection";
import PropertyDetailsSection from "../components/PropertyDetailsSection";
import DeletePropertyButton from "../components/DeletePropertyButton";
import NavigationHeader from "../components/NavigationHeader";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/InfoItem.css";

const PropertyHomePage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const vanityPropertyId = window.location.pathname.split("/")[2];

  const { SaveStatus, propertyData, userData, systemContent } = props;

  const propertyDetailsPlaceholder = systemContent.find(
    (content) => content.DetailLabel === "property-details-placeholder"
  );

  const propertyDetails = propertyData.find(
    (item) => item.PropertyID === vanityPropertyId
  );

  const propertyId = propertyDetails._id;

  const basicSetupIsChecked = [
    propertyDetails.PropertyTitle,
    propertyDetails.PropertyCoverImage,
    propertyDetails.GeoLocationFormattedAddress,
    propertyDetails.PropertyDisplayAddress,
    propertyDetails.PropertyHexPrimary ||
      propertyDetails.PropertyHexSecondary ||
      propertyDetails.CompanyProfile,
  ].every(Boolean);

  const liveStatusIsChecked =
    userData.SubscriptionActiveUser &&
    userData.SubscriptionActiveSystem &&
    propertyDetails.PropertyActiveStatus &&
    propertyDetails.PublishedStatus;

  const activeSectionTitle = liveStatusIsChecked ? "Live" : "Inactive";

  const fromPage = sessionStorage.getItem("routePath") || "dashboard";
  const previousPageMap = {
    dashboard: { title: "Dashboard", path: "/" },
    "all-properties": { title: "All Properties", path: "/properties" },
  };
  const { title: previousPageTitle, path: previousPagePath } =
    previousPageMap[fromPage] || previousPageMap.dashboard;

  const imageSource =
    propertyDetails.PropertyCoverImage ||
    propertyDetailsPlaceholder.DetailImage;

  return (
    <>
      <NavigationHeader
        {...props}
        propertyDetails={propertyDetails}
        pathRoot="/"
        currentPageTitle={propertyDetails.PropertyTitle || "Unnamed Place"}
        previousPageTitle={previousPageTitle}
        previousPagePath={previousPagePath}
      />

      <div className="canvas-with-header-navheader-and-footer">
        <div className="canvas-with-header-and-footer-inner-container">
          <div className="h1-header-large">
            {propertyDetails.PropertyTitle || "Unnamed Place"}
          </div>

          <div className="content-and-ad-banner-container">
            <div className="heading-and-image-container">
              <img
                className="infopage-image"
                src={`${imageSource}?width=450&aspect_ratio=16:9`}
                alt={
                  propertyDetails.PropertyTitle ||
                  propertyDetailsPlaceholder.DetailTitle
                }
              />

              <PropertyDetailsSection
                {...props}
                SectionType="link"
                IsChecked={liveStatusIsChecked}
                PropertyActiveStatus={propertyDetails.PropertyActiveStatus}
                PublishedStatus={propertyDetails.PublishedStatus}
                userData={userData}
                propertyDetails={propertyDetails}
                IsRequired
                ShowActionButton={!liveStatusIsChecked}
                SectionTitle={activeSectionTitle}
                SaveStatus={SaveStatus}
                propertyVanityId={propertyDetails.PropertyID}
                propertyId={propertyId}
              />
            </div>

            <div className="infoitem-container">
              <div className="section-break-wrapper">
                <div className="section-break"></div>
              </div>
            </div>

            <PropertyDetailsSection
              {...props}
              propertyDetails={propertyDetails}
              SectionType="non-link"
              IsChecked={basicSetupIsChecked}
              IsRequired={false}
              ShowActionButton={liveStatusIsChecked && !basicSetupIsChecked}
              SectionTitle="Basic Setup"
              SectionText="Property, location and host details"
              SaveStatus={SaveStatus}
              propertyVanityId={propertyDetails.PropertyID}
              propertyId={propertyId}
              Target={`/property/${vanityPropertyId}/property-details`}
            />

            {userData.MessagingActiveStatus && (
              <>
                <PropertyDetailsSection
                  {...props}
                  SectionType="non-link"
                  IsChecked={propertyDetails.CommsActiveStatus}
                  IsRequired={false}
                  ShowActionButton={false}
                  SectionTitle="Messaging"
                  SectionText="Edit messaging settings for this property"
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyId}
                  Target={`/property/${vanityPropertyId}/messaging`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>
              </>
            )}

            <ContentSection {...props} />

            <DeletePropertyButton {...props} propertyId={propertyId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyHomePage;
