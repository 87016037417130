import React from "react";
import { connect } from "react-redux";
import { fetchUser } from "../redux/actions/userActions";
import "../styles/LoginForm.css";
import { blockedDomains } from "../data/blockedDomains";
import containsString from "../functions/containsString";
import Loading from "./Loading";
import GuideyLogo from "../icons/GuideyLogo";
// import CtaLoading from "./CtaLoading";

class LoginForm extends React.Component {
  state = {
    email: "",
    password: "",
    company_fax_number: "",
    loading: false,
    errorMessage: "",
  };

  componentDidMount = () => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.responseStatus.userFound !== this.props.responseStatus.userFound
    ) {
      if (
        !this.props.responseStatus.userFound &&
        this.props.responseStatus.userFound !== undefined
      ) {
        this.setState({
          errorMessage:
            "Sorry, we could not find a user with that email address. Please try again.",
        });
      }
      if (this.props.responseStatus.userFound) {
        this.setState({
          errorMessage: "Sorry, incorrect password entered. Please try again.",
        });
      }
    }
  }

  onClickForgotPassword = () => {
    this.props.history.push("/forgot-password");
  };

  onClickCreateAccount = () => {
    this.props.history.push("/create-account");
    // window.location.href = `https://try${topLevelDomain}start`;
  };

  handleOnChange = (e) => {
    e.persist();
    this.setState(() => ({
      [e.target.name]: e.target.value,
    }));
    this.setState({
      errorMessage: "",
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      errorMessage: "",
    });

    const domainBlocked = containsString(this.state.email, blockedDomains);

    if (!domainBlocked) {
      if (
        this.state.company_fax_number === "" &&
        this.state.email &&
        this.state.password
      ) {
        this.props.fetchUser(this.state);
      }
    } else {
      this.setState({
        errorMessage: "Please enter a valid email address and try again.",
      });
    }
  };

  render() {
    const { FetchStatus } = this.props;

    if (FetchStatus === "loading-data") {
      return <Loading />;
    }

    return (
      <>
        <div className="homepage-canvas">
          {
            // <div className="inline-static-link-grey">
            // airguide is now Guidey™{" "}
            //  <a
            //   href="https://guideyapp.com/from-airguide-to-guidey"
            //   target="_blank"
            //   rel="noopener noreferrer"
            // >
            //   find out more.
            //  </a>
            // </div>
          }

          <div className="login-form-wrapper">
            <div className="homepage-logo-wrapper">
              <GuideyLogo width={"180"} height={"auto"} />
            </div>
            <div className="homepage-heading">Log in to your account</div>

            <div className="login-form">
              <form onSubmit={this.onSubmit}>
                <input
                  type="text"
                  name="company_fax_number"
                  style={{ display: "none" }}
                />
                <input
                  type="text"
                  name="email"
                  className="login-text-input"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.handleOnChange}
                />
                <br />
                <input
                  type="password"
                  name="password"
                  className="login-text-input"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleOnChange}
                />
                <br />

                <input
                  type="submit"
                  className="login-button"
                  value={FetchStatus === "fetching" ? "Logging in..." : "Login"}
                />
              </form>

              {this.state.errorMessage && (
                <div className="infoitem-form-error-message-centre-align">
                  {this.state.errorMessage}
                </div>
              )}

              <button
                className="inline-edit-link-grey"
                onClick={this.onClickForgotPassword}
              >
                Forgot password?
              </button>

              <button
                className="inline-edit-link"
                onClick={this.onClickCreateAccount}
              >
                Create an account
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (userInfo) => dispatch(fetchUser(userInfo)),
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
