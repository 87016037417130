import React from "react";
import { connect } from "react-redux";
// import { createAccount } from "../redux/actions/userActions";
import { workflowApiRoot, apiSecretToken } from "../constants.js";
import { appConfig } from "../data/appConfig";
import { blockedDomains } from "../data/blockedDomains";
import containsString from "../functions/containsString";
import { setSaveStatus, fetchUser } from "../redux/actions/userActions";
import LoadingButtonTwo from "./LoadingButtonTwo";
import "../styles/InfoItem.css";

class CreateAffiliateAccountModule extends React.Component {
  state = {
    payload: {},
    errorMessage: "",
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.body.style.setProperty("--tidio-display", "none");
    const { trackingData } = this.props;
    this.setState(() => ({
      payload: {
        referral_id:
          trackingData && trackingData.referral_id
            ? trackingData.referral_id
            : "",
        utm_source:
          trackingData && trackingData.utm_source
            ? trackingData.utm_source
            : "",
        utm_medium:
          trackingData && trackingData.utm_medium
            ? trackingData.utm_medium
            : "",
        utm_campaign:
          trackingData && trackingData.utm_campaign
            ? trackingData.utm_campaign
            : "",
        utm_term:
          trackingData && trackingData.utm_term ? trackingData.utm_term : "",
        utm_content:
          trackingData && trackingData.utm_content
            ? trackingData.utm_content
            : "",
        landing_page:
          trackingData && trackingData.landing_page
            ? trackingData.landing_page
            : "",
        sourceUrl:
          window.location && window.location.hostname
            ? window.location.hostname
            : "",
        sourceBrand:
          appConfig && appConfig.brandName
            ? appConfig.brandName.toLowerCase()
            : "",
      },
    }));
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    var payloadB = { ...this.state.payload };
    payloadB[e.target.name] = e.target.value;
    var payloadC = { ...this.state.payload };
    payloadC[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA, ...payloadB, ...payloadC },
    }));
  };

  onSubmit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.setState({
      errorMessage: "",
    });

    const domainBlocked = containsString(
      this.state.payload.email,
      blockedDomains
    );
    // console.log("domainBlocked", domainBlocked);
    if (!domainBlocked) {
      // console.log("Domain not blocked");
      this.props.setSaveStatus(`${ItemLabel}-fetching`);
      fetch(`${workflowApiRoot}create-affiliate-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`,
        },
        body: JSON.stringify(this.state.payload),
      })
        .then((response) => response.json())
        .then((results) => {
          console.log(results);
          if (results.status !== "success") {
            this.props.setSaveStatus(`${ItemLabel}-error`);
            this.setState({
              errorMessage:
                "This email address is already in use. Please try again.",
            });
          }
          if (results.status === "success") {
            this.props.updateState();
            //  this.props.history.push("/welcome");
            this.props.fetchUser(this.state.payload);
            // this.props.setSaveStatus(`${ItemLabel}-success`);
          }
        });
    }
    //  console.log("Domain blocked"),
    else {
      this.props.setSaveStatus(`${ItemLabel}-error`);
      this.setState({
        errorMessage: "Please enter a valid email address and try again.",
      });
    }
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      InputLabelA,
      ValueA,
      PayloadA,
      InputLabelB,
      ValueB,
      PayloadB,
      InputLabelC,
      ValueC,
      PayloadC,
    } = this.props;

    // console.log("payload", this.state.payload);
    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-form-container">
            <form className="infoitem-form" onSubmit={this.onSubmit}>
              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelC} </label>
                <input
                  type="text"
                  name={PayloadC}
                  className="infoitem-text-input"
                  placeholder={`Enter ${InputLabelC}`}
                  defaultValue={ValueC}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelA} </label>
                <input
                  type="email"
                  name={PayloadA}
                  className="infoitem-text-input"
                  placeholder={`Enter ${InputLabelA}`}
                  defaultValue={ValueA}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelB} </label>
                <input
                  type="password"
                  name={PayloadB}
                  className="infoitem-text-input"
                  placeholder={`Enter ${InputLabelB}`}
                  defaultValue={ValueB}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                />

                <div className="infoitem-form-inner-container-left">
                  <LoadingButtonTwo
                    ItemLabel={ItemLabel}
                    ClickFunction={this.onSubmit}
                    StaticText={"Join now"}
                    LoadingText={"Submitting"}
                    Style={"cta"}
                    Loading={
                      SaveStatus === `${ItemLabel}-fetching` ||
                      SaveStatus === undefined ||
                      SaveStatus === `${ItemLabel}-success`
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </form>

            {SaveStatus === `${ItemLabel}-error` && (
              <div
                style={{ marginBottom: "15px" }}
                className="infoitem-form-error-message"
              >
                {this.state.errorMessage}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (userInfo) => dispatch(fetchUser(userInfo)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
});

export default connect(null, mapDispatchToProps)(CreateAffiliateAccountModule);
