export const blockedDomains = [
  ".kp",
  ".onion",
  ".ru",
  "binance-support.com",
  "bk.ru",
  "co.ryongnamsan.edu.kp",
  "google-securemail.com",
  "inbox.ru",
  "kass.org.kp",
  "kcna.kp",
  "keemail.me",
  "kraken-exchange.net",
  "lelantos.org",
  "list.ru",
  "mail.ru",
  "micro-softsecurity.com",
  "pochta.ru",
  "qq.com",
  "rambler.ru",
  "samsung-corp.com",
  "sigaint.org",
  "star-co.net.kp",
  "tutanota.com",
  "ya.ru",
  "yandex.com",
  "yandex.ru",
  "10minemail.net",
  "10minemail.net",
  "10minutemail.com",
  "10minutemail.com",
  "420blaze.it",
  "airmail.cc",
  "amazonsupport.com",
  "anonbox.net",
  "anonbox.net",
  "anonmails.de",
  "appleid-verification.com",
  "armyspy.com",
  "bareed.ws",
  "bigprofessor.so",
  "binkmail.com",
  "bk.ru",
  "bobmail.info",
  "bugmenot.com",
  "cock.li",
  "cool.fr.nf",
  "courriel.fr.nf",
  "courriel.fr.nf",
  "criptext.com",
  "cuvox.de",
  "dayrep.com",
  "disposableemail.com",
  "disposableemailaddress.com",
  "disposablemail.com",
  "dispostable.com",
  "dispostable.com",
  "einrot.com",
  "email-10minutemail.com",
  "email-temp.com",
  "email-temp.org",
  "emailtemporario.com.br",
  "fakeemailgenerator.com",
  "fakeinbox.com",
  "fakeinbox.com",
  "fakemailgenerator.com",
  "fleckens.hu",
  "gmai1.com",
  "googl-security.com",
  "grr.la",
  "grr.la",
  "guerrillamail.biz",
  "guerrillamail.com",
  "guerrillamail.com",
  "guerrillamail.net",
  "guerrillamail.org",
  "gufum.com",
  "gustr.com",
  "inboxbear.com",
  "inboxclean.com",
  "inboxproxy.com",
  "incognitomail.org",
  "jetable.fr.nf",
  "jourrapide.com",
  "keemail.me",
  "list.ru",
  "mail.ru",
  "mailcatch.com",
  "maildrop.cc",
  "maildrop.cc",
  "maildx.com",
  "mailfence.com",
  "mailforspam.com",
  "mailinator.com",
  "mailinator.com",
  "mailinator.net",
  "mailinator.org",
  "mailinator2.com",
  "mailna.co",
  "mailna.co",
  "mailna.in",
  "mailnesia.com",
  "mailnesia.com",
  "mailsac.com",
  "mailtemp.info",
  "mega.zik.dj",
  "micros0ft.com",
  "mkzaso.com",
  "mohmal.com",
  "mohmal.com",
  "mohmal.im",
  "mohmal.in",
  "mt2015.com",
  "mvrht.com",
  "mvrht.com",
  "mytrashmail.com",
  "nomail.xl.cx",
  "nospam.ze.tc",
  "notsharingmy.info",
  "objectmail.com",
  "onewaymail.com",
  "paypall.com",
  "pm.me",
  "pokemail.net",
  "privateremail.com",
  "protonmail.com",
  "rambler.ru",
  "rcpt.at",
  "reallymymail.com",
  "rhyta.com",
  "riseup.net",
  "rtrtr.com",
  "secmail.pro",
  "sharklasers.com",
  "sharklasers.com",
  "sigaint.org",
  "spam4.me",
  "spam4.me",
  "spambog.com",
  "spamgourmet.com",
  "spamgourmet.com",
  "spammail.me",
  "spamox.com",
  "speed.1s.fr",
  "superrito.com",
  "teleworm.us",
  "teleworm.us",
  "temp-mail.com",
  "temp-mail.org",
  "tempail.com",
  "tempemail.co",
  "tempinbox.com",
  "tempmail.com",
  "tempmail.com",
  "tempmail.net",
  "tempmail.net",
  "tfwno.gf",
  "thankyou2010.com",
  "thankyou2011.com",
  "thankyou2012.com",
  "thisisnotmyrealemail.com",
  "throwawaymail.com",
  "trash-me.com",
  "trashmail.com",
  "trashmail.net",
  "trashmail.org",
  "tuta.io",
  "tutanota.com",
  "tutanota.de",
  "yeggt.com",
  "yopmail.com",
  "yopmail.com",
  "yopmail.fr",
  "yopmail.fr",
  "yopmail.net",
  "zmail",
];
