import { dataApiRoot, apiSecretToken } from "../constants.js";
import { setSystemCategories } from "../redux/actions/userActions.js";

export const fetchSystemContentCategories = async (dispatch, systemId) => {
  if (systemId) {
    const fetchSystemContentCategoriesData = async function (cursor) {
      let apiUrl = `${dataApiRoot}contentcategory?constraints=[{%22key%22:%22systemid%22,%22constraint_type%22:%22equals%22,%22value%22:%22${systemId}%22}]&sort_field=listrank&cursor=${cursor}`;
      var apiResults = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`,
        },
      }).then((resp) => {
        return resp.json();
      });
      return apiResults;
    };

    const fetchAllSystemContentCategoriesData = async function (cursor = 0) {
      const data = await fetchSystemContentCategoriesData(cursor);
      const { remaining, results } = data.response;
      //  console.log("Retreiving data from API for cursor position: " + cursor);
      if (remaining > 0) {
        cursor += results.length;
        return results.concat(
          await fetchAllSystemContentCategoriesData(cursor)
        );
      } else {
        return results;
      }
    };

    (async () => {
      const allSystemContentCategoriesData =
        await fetchAllSystemContentCategoriesData();

      dispatch(setSystemCategories(allSystemContentCategoriesData));
    })();
  }
};
