import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Popup from "reactjs-popup";
import CrossIcon from "../icons/CrossIcon";
import ContentItemEditor from "./ContentItemEditor";
import CTAButtonIconLeft from "./CTAButtonIconLeft";
import CirclePlusIcon from "../icons/CirclePlusIcon";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItemMultiPopup.css";

const ContentEditorPopup = (props) => {
  const {
    buttonText,
    itemType,
    itemData,
    itemLabel,
    categoryLabel,
    newItemListRank,
    propertyId,
  } = props;

  const itemUniqueId = itemData ? itemData._id : "new-item";
  const dispatch = useDispatch();
  const [contentEdited, setContentEdited] = useState(false);
  const [saveChangesPopupVisible, setSaveChangesPopupVisible] = useState(false);
  const saveStatus = useSelector(
    (state) => state.fetchReducer.saveStatus,
    shallowEqual
  );

  const userData = useSelector(
    (state) => state.userReducer.user.userData,
    shallowEqual
  );

  const systemContent = useSelector(
    (state) => state.userReducer.user.systemContent,
    shallowEqual
  );

  const customContent = useSelector(
    (state) => state.userReducer.user.linkedCustomContent,
    shallowEqual
  );

  const propertyData = useSelector(
    (state) => state.userReducer.user.linkedProperties,
    shallowEqual
  );

  const sharedContent = useSelector(
    (state) => state.userReducer.user.sharedCustomContent,
    shallowEqual
  );

  const sharedContentProfiles = useSelector(
    (state) => state.userReducer.user.sharedContentProfiles,
    shallowEqual
  );

  const sharedPropertyContentProfileFilter = sharedContentProfiles?.filter(
    (item) => item.PropertyContentProfile === true
  );

  const sharedPropertyContentProfileId =
    sharedPropertyContentProfileFilter?.[0]?._id || null;

  const isShared = window.location.pathname.split("/")[1] === "shared-content";

  useEffect(() => {
    setSaveChangesPopupVisible(false);
  }, []);

  //console.log("saveChangesPopupVisible", saveChangesPopupVisible);
  return (
    <>
      <Popup
        modal
        lockScroll={true}
        contentStyle={{
          border: "none",
          padding: "5px",
          margin: "auto",
          width: "auto",
          height: "auto",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
        trigger={
          itemType === "edit" ? (
            <button className="infoitem-edit-button">{buttonText}</button>
          ) : itemType === "add" ? (
            <CTAButtonIconLeft
              Icon={CirclePlusIcon}
              Heading={buttonText}
              Style={"solid"} // "solid" or "inverted"
            />
          ) : null
        }
      >
        {(close) => (
          <>
            {saveStatus === `${itemUniqueId}-success` &&
              (close(),
              setSaveChangesPopupVisible(false),
              dispatch(setSaveStatus(`${itemUniqueId}-idle`)))}
            <div className="infoitemmulti-popup-modal-box">
              {!saveChangesPopupVisible && (
                <div className="infoitemmulti-popup-modal-close-button-wrapper">
                  <button
                    className="infoitemmulti-popup-modal-close-button"
                    onClick={
                      contentEdited
                        ? () =>
                            setTimeout(
                              () => setSaveChangesPopupVisible(true),
                              0
                            )
                        : close
                    }
                  >
                    <div className="infoitemmulti-popup-modal-close-button-icon-wrapper">
                      <div className="infoitemmulti-popup-modal-close-button-icon">
                        <CrossIcon />
                      </div>
                    </div>
                  </button>
                </div>
              )}

              <div className="infoitemmulti-popup-modal-content-wrapper">
                <ContentItemEditor
                  PassedCategoryLabel={categoryLabel}
                  ItemLabel={itemLabel}
                  newItemListRank={newItemListRank}
                  itemData={itemData}
                  DetailLabel={itemData?.DetailLabel || "custom"}
                  ItemUniqueId={itemUniqueId}
                  InfoType={itemData?.InfoType || "custom"}
                  ContentType={itemData?.ContentType || "details"}
                  IsCustomLabel={
                    itemData && itemData.IsCustomLabel
                      ? itemData.IsCustomLabel
                      : false
                  }
                  saveChangesPopupVisible={saveChangesPopupVisible}
                  popUpClose={close}
                  setContentEdited={setContentEdited}
                  contentEdited={contentEdited}
                  showSaveChangesPopup={() => setSaveChangesPopupVisible(true)}
                  hideSaveChangesPopup={() => setSaveChangesPopupVisible(false)}
                  SaveStatus={saveStatus}
                  userId={userData._id}
                  systemContent={systemContent}
                  customContent={customContent}
                  sharedContent={sharedContent}
                  propertyDetails={propertyData}
                  PropertyId={propertyId}
                  SharedContentProfileId={sharedPropertyContentProfileId}
                  isComponentShared={isShared}
                />
              </div>
            </div>
          </>
        )}
      </Popup>
    </>
  );
};

export default ContentEditorPopup;
