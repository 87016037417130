import React, { useCallback, useEffect, useState } from "react";
//import { useLocation } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import ContentEditorPopup from "./ContentEditorPopup";
import Loading from "./Loading";
import ContentListItem from "../components/ContentListItem";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/InfoItem.css";

const ContentSection = (props) => {

 // Get global state
  const {
    systemCategories,
    customCategories,
    systemContent,
    linkedProperties,
  } = useSelector((state) => state.userReducer.user, shallowEqual);
  const propertyId = useSelector(
    (state) => state.userReducer.propertyId,
    shallowEqual
  );
  const { customContent } = props;

  const sharedContent = useSelector(
    (state) => state.userReducer.user.sharedCustomContent,
    shallowEqual
  );

  const propertyDetails = linkedProperties.find(
    (item) => item._id === propertyId
  );

  const themeLabel =
    propertyDetails &&
    propertyDetails.ThemeLabel &&
    (propertyDetails.ThemeLabel === "default" ||
      propertyDetails.ThemeLabel === "custom")
      ? "str"
      : propertyDetails &&
        propertyDetails.ThemeLabel &&
        (propertyDetails.ThemeLabel !== "default" ||
          propertyDetails.ThemeLabel !== "default")
      ? propertyDetails.ThemeLabel
      : "str";

  // Initiate local props and state
  const [loading, setLoading] = useState(true);
  const [templateLabel] = useState(themeLabel);

  
  const currentPathname = window.location.pathname;
  const pathParts = currentPathname.split("/");
  const isShared = pathParts[1] === "shared-content" ? true : false;
  const isTopLevel = pathParts.length === 3 ? true : false;
  const isSecondLevel = pathParts.length === 5 ? true : false;
  const [categoryLabel, setCategoryLabel] = useState(
    pathParts.length > 3 ? pathParts[pathParts.length - 1] : ""
  );
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [customContentFound, setCustomContentFound] = useState([]);
  const [newItemListRank, setNewItemListRank] = useState(0);
  const [allContentCategoriesFiltered, setAllContentCategoriesFiltered] =
    useState([]);

  // Update categoryLabel when page path changes
  useEffect(() => {
    // setLoading(true);
    const updatedPathParts = currentPathname.split("/");
    const updatedCategoryLabel =
      updatedPathParts.length > 3
        ? updatedPathParts[updatedPathParts.length - 1]
        : "";
    setCategoryLabel(updatedCategoryLabel);
  }, [currentPathname]);

  // Initiate data processing
  useEffect(() => {
    if (
      isShared &&
      currentPathname &&
      systemCategories &&
      customCategories &&
      systemContent &&
      sharedContent
    ) {
      processData();
    }
    if (
      !isShared &&
      currentPathname &&
      systemCategories &&
      customCategories &&
      systemContent &&
      customContent
    ) {
      processData();
    }
  }, [
    isShared,
    categoryLabel,
    systemCategories,
    customCategories,
    systemContent,
    sharedContent,
    customContent,
  ]);

  // Filter data and update local state
  const processData = () => {
    // setLoading(true);
    const sectionContent = isShared ? sharedContent : customContent;
    // Remove duplicates from systemContent already set up in customContent
    const customContentLabels = new Set(
      sectionContent?.map((item) => item.DetailLabel) || []
    );

    const systemContentFiltered = systemContent.filter(
      (item) => !customContentLabels.has(item.DetailLabel)
    );

    // Update systemContent to include InfoType
    const systemContentUpdated = systemContentFiltered.map((obj) => ({
      ...obj,
      InfoType: "system",
    }));

    const allSectionContent =
      sectionContent?.filter(
        (item) =>
          item.DetailTitle ||
          item.DetailText ||
          item.DetailTextDescribe ||
          item.DetailTextLocate ||
          item.DetailTextOperate
      ) || [];

    const sectionContentUpdated = allSectionContent.map((obj) => ({
      ...obj,
      InfoType: "custom",
    }));

    const sectionContentUpdatedFiltered = isShared
      ? sectionContentUpdated.filter((item) => item.LinkedSharedContentProfile)
      : sectionContentUpdated.filter(
          (item) =>
            !item.LinkedSharedContentProfile &&
            item.LinkedProperty.includes(propertyId)
        );

    const allSectionContentMerged = [
      ...sectionContentUpdated,
      ...systemContentUpdated,
    ];

    const allContentInSection = allSectionContentMerged.filter(
      (item) => item.CategoryLabel === categoryLabel
    );

    const sectionContentMerged = [
      ...sectionContentUpdatedFiltered, // this needs shared filter
      ...systemContentUpdated,
    ];

    const contentInSection = sectionContentMerged.filter(
      (item) => item.CategoryLabel === categoryLabel
    );

    const customCategoriesFiltered = isShared
      ? customCategories.filter((item) => item.LinkedSharedContentProfile)
      : customCategories.filter(
          (item) =>
            !item.LinkedSharedContentProfile &&
            item.LinkedProperty.includes(propertyId)
        );

    // Filter systemCategories to remove duplicates already set up in customCategories
    const customCategoryLabelsLookup = new Set(
      customCategoriesFiltered.map((item) => item.CategoryLabel)
    );

    const systemCategoriesDuplicatesRemoved = systemCategories.filter(
      (item) => !customCategoryLabelsLookup.has(item.CategoryLabel)
    );

    const systemCategoriesFiltered = systemCategoriesDuplicatesRemoved.filter(
      (item) =>
        (isTopLevel
          ? !item.ParentCategoryLabel
          : item.ParentCategoryLabel === categoryLabel) &&
        item.LinkedTemplates?.includes(templateLabel) &&
        (!isShared || item.CategoryLabel !== "local-area")
    );

    const systemCategoriesUpdated = systemCategoriesFiltered.map((obj) => ({
      ...obj,
      InfoType: "system",
    }));

    const allCustomCategories = customCategoriesFiltered.filter(
      (item) =>
        (isTopLevel
          ? !item.ParentCategoryLabel
          : item.ParentCategoryLabel === categoryLabel) &&
        item.CategoryLabel !== "local-area"
    );

    const allCustomCategoriesUpdated = allCustomCategories.map((obj) => ({
      ...obj,
      InfoType: "custom",
    }));

    // Filter customCategories if Shared Category or not
    const allCustomCategoriesSharedFilter = allCustomCategoriesUpdated.filter(
      (item) =>
        isShared
          ? item.LinkedSharedContentProfile
          : !item.LinkedSharedContentProfile
    );

    const allContentCategoriesFiltered = [
      ...allCustomCategoriesSharedFilter,
      ...systemCategoriesUpdated,
      ...contentInSection,
    ].sort((a, b) => a.ListRank - b.ListRank);

    setAllContentCategoriesFiltered(allContentCategoriesFiltered);

    // Includes Shared and Property Categories

    const allSectionContentCategories = [
      ...allCustomCategoriesUpdated, // Change to version before shared filter - done
      ...systemCategoriesUpdated, // Leave as is - done
      ...allContentInSection, // New version before shared filter - done
    ];

    const newItemListRank =
      allSectionContentCategories.length > 0
        ? allSectionContentCategories[allSectionContentCategories.length - 1]
            .ListRank + 1
        : 1;
    setNewItemListRank(newItemListRank);

    // Filters for checkIsChecked lookup
    const customCategoryLabels = new Set(
      allSectionContent.map((item) => item.CategoryLabel)
    );

    const customCategoriesFound = [
      ...customCategories,
      ...systemCategories,
    ].filter((item) => customCategoryLabels.has(item.CategoryLabel));

    const parentCategoryLabels = new Set(
      customCategoriesFound.map((item) => item.ParentCategoryLabel)
    );

    const parentCategoriesFound = allSectionContentCategories.filter((item) =>
      parentCategoryLabels.has(item.CategoryLabel)
    );

    const contentDetailLabels = new Set(
      allSectionContent.map((item) => item.DetailLabel)
    );

    const customContentFound = allContentInSection.filter((item) =>
      contentDetailLabels.has(item.DetailLabel)
    );

    setCustomContentFound(customContentFound);

    const uniqueCategories = [
      ...customCategoriesFound,
      ...parentCategoriesFound,
    ];
    setUniqueCategories(uniqueCategories);
    setLoading(false);
  };

  // Lookup function to check if content found
  const checkIfChecked = useCallback((detailLabel, categoryLabel, data) => {
    return data.some((item) =>
      item && detailLabel
        ? item.DetailLabel === detailLabel
        : item && categoryLabel
        ? item.CategoryLabel === categoryLabel
        : null
    );
  }, []);

  // End

  if (loading) return <Loading LoadingCanvas={"container-loading-canvas"} />;

  if (!loading)
    return (
      <>
        {allContentCategoriesFiltered &&
          uniqueCategories &&
          allContentCategoriesFiltered.map((item, index) => (
            <div key={index}>
              <ContentListItem
                {...props}
                itemData={item}
                isChecked={checkIfChecked(
                  item.DetailLabel,
                  item.CategoryLabel,
                  item.DetailLabel ? customContentFound : uniqueCategories
                )}
                sectionTitle={item.CategoryTitle || item.DetailTitle}
                sectionText={item.CategoryShortDescription || ""}
                isRequired={item.isRequired || false}
                propertyId={propertyId}
                targetType={item.CategoryTitle ? "category" : "content"}
                categoryTarget={
                  isTopLevel && item.CategoryLabel !== "local-area"
                    ? `${currentPathname}/content/${item.CategoryLabel}`
                    : `${currentPathname}/${item.CategoryLabel}`
                }
                categoryLabel={categoryLabel}
                itemLabel={
                  (item.CategoryTitle && item.CategoryTitle.toLowerCase()) ||
                  (item.DetailTitle && item.DetailTitle.toLowerCase())
                }
              />
            </div>
          ))}

        {allContentCategoriesFiltered.length === 0 &&
          customContentFound.length === 0 && (
            <>
              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <div style={{ marginBottom: "30px" }}>
                No content has been added to this section yet.
              </div>
            </>
          )}

        {allContentCategoriesFiltered.length > 0 && (
          <div className="infoitem-container">
            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>
          </div>
        )}

        {!isTopLevel && !isSecondLevel && !loading && (
          <ContentEditorPopup
            buttonText={"Add content"}
            itemType={"add"}
            itemData={null}
            categoryLabel={categoryLabel}
            itemLabel={"new-content-item"}
            newItemListRank={newItemListRank}
            propertyId={propertyId}
          />
        )}
      </>
    );
};

export default ContentSection;
