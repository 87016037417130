import React, { useEffect } from "react";
import { appConfig } from "../data/appConfig";
import NavigationHeader from "../components/NavigationHeader";
import NotificationBanner from "../components/NotificationBanner";
import ContentSection from "../components/ContentSection";
import "../styles/InfoPage.css";

const SharedPropertyContentPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onButtonClick = () => {
    props.history.push("/local-area-profiles");
  };

  const currentPageTitle = "Shared Property Content";

  return (
    <>
      <NavigationHeader
        {...props}
        previousPageTitle="Dashboard"
        previousPagePath="/"
        currentPageTitle={currentPageTitle}
        pathRoot={"shared-content"}
      />
      <div className="canvas-with-header-navheader-and-footer">
        <div className="canvas-with-header-and-footer-inner-container">
          <div className="content-and-ad-banner-container">
            <div className="heading-and-image-container">
              <div className="h1-header-large">{currentPageTitle}</div>
              <div className="infopage-subheading">
                Create and edit content to share across multiple properties
              </div>

              <NotificationBanner
                Text="Save time! Any content you set up in this section can be shared across all of your properties with the click of a button"
                DisplayButton
                ExternalLink
                ButtonText="How it works"
                Target={`${appConfig.siteUrl}/help/shared-content`}
                IconType="Bell"
                IconColour="#1e8fc1"
                TextColour="#1e8fc1"
                ButtonColour="#1e8fc1"
                BorderColour="#1e8fc1"
                {...props}
              />
            </div>
          </div>

          <ContentSection {...props} />

          <button
            className="create-new-button-inverted"
            onClick={onButtonClick}
          >
            Shared local area profiles
          </button>
        </div>
      </div>
    </>
  );
};

export default SharedPropertyContentPage;
